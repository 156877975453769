import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCell, { AvatarCellProps } from "./AvatarCell";

export class AvatarImageError extends Error {
    constructor(message: string) {
        super(`[AvatarImageError] Error - ${message}`);
    }
}

export type AvatarImageProps =  AvatarCellProps & {aspect: number}

export default class AvatarImage extends AvatarCell {

    protected _aspect: number

    constructor(props: AvatarImageProps, id: StringId) {
        super(props, id)
        this._aspect = props.aspect
    }

    get aspect(): number {
        return this._aspect;
    }

    public equals(object: AvatarImage): boolean {
        return super.equals(object) && this._aspect === object?._aspect
    }

}
