import Avatar from "../../../../domain/modules/avatar/entity/Avatar";
import AvatarCategoryList from "../../../../domain/modules/avatar/valueObject/AvatarCategoryList";
import AvatarFlowList from "../../../../domain/modules/avatar/valueObject/AvatarFlowList";
import AvatarList from "../../../../domain/modules/avatar/valueObject/AvatarList";
import IMapper from "../../../../infrastructure/IMapper";
import { AvatarViewModel, AvatarCategoryViewModel, AvatarFlowViewModel } from "../viewModel/AvatarViewModels";



export default class AvatarPresentation implements IMapper<Avatar, AvatarViewModel> {

    present(avatarList: AvatarList): AvatarViewModel[] {
        const avatarViewModelList = avatarList.avatars.map(avatar => {
            return this.map(avatar)
        })
        return avatarViewModelList
    }

    map(input: Avatar): AvatarViewModel {
        const { id, categoryList  } = input;
        return {
            id: id.getId(),
            categoryList: this.mapCategories(categoryList)
        }
    }
    
    mapCategories(input: AvatarCategoryList): AvatarCategoryViewModel[] {
        const avatarCategories = input.categories.map(category => {
            const { id, name, flowList  } = category;
            return {
                id: id.getId(),
                name: name.value,
                flowList: this.mapFlows(flowList)
            }
        })
        return avatarCategories
    }

    mapFlows(input: AvatarFlowList): AvatarFlowViewModel[] {
        const avatarFlows = input.flows.map(flow => {
            const { id, isDrafted, isPublished, name } = flow;
            return {
                id: id.getId(),
                name: name.value,
                isDrafted: isDrafted,
                isPublished: isPublished
            }
        })
        return avatarFlows
    }
}