import { IGetAvatarsOutput } from "../../../domain/modules/avatar/useCase/GetAvatarsUseCase";
import { GET_AVATARS_SUCCESS } from "../../redux/avatar/AvatarActionTypes";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import { IReduxDispatch } from "../IReduxDispatch";
import AvatarList from "../../../domain/modules/avatar/valueObject/AvatarList";
import AvatarPresentation from "./presentation/AvatarPresentation";


export default class GetAvatarPresenter extends ReduxPresenter implements IGetAvatarsOutput {

    constructor(dispatch: IReduxDispatch, private presentation: AvatarPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse = (avatarList: AvatarList): void => {
        const avatarViewModelList = this.presentation.present(avatarList);

        this.dispatch({
            type: GET_AVATARS_SUCCESS,
            payload: { 
                avatarList: avatarViewModelList
            }
        })
    }

}