import Avatar from "../../../../domain/modules/avatar/entity/Avatar";
import AvatarFlow from "../../../../domain/modules/avatar/entity/AvatarFlow";
import AvatarCategoryView from "../../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import AvatarFlowView from "../../../../domain/modules/avatar/entity/view/AvatarFlowView";
import { AvatarCategoryInputs } from "../../../../domain/modules/avatar/useCase/processor/AddAvatarCategoryInputProcessor";
import { AvatarFlowInputs } from "../../../../domain/modules/avatar/useCase/processor/AddAvatarFlowInputProcessor";
import { AvatarCategoryInputData, AvatarCategoryViewData, AvatarData, AvatarFlowData, AvatarFlowInputData, AvatarFlowViewData } from "../../../dataSource/avatarDataSource/IAvatarDataSource";
import IMapper from "../../../IMapper";
import AvatarCategoryInputDataMapper from "../mapper/AvatarCategoryInputDataMapper";
import AvatarCategoryViewDataMapper from "../mapper/AvatarCategoryViewDataMapper";
import AvatarCategoryViewMapper from "../mapper/AvatarCategoryViewMapper";
import AvatarFlowInputDataMapper from "../mapper/AvatarFlowInputDataMapper";
import AvatarFlowMapper from "../mapper/AvatarFlowMapper";
import AvatarFlowViewMapper from "../mapper/AvatarFlowViewMapper";
import AvatarMapper from "../mapper/AvatarMapper";
import IAvatarMapperFactory from "./IAvatarMapperFactory";

export default class AvatarRepositoryMapperFactory implements IAvatarMapperFactory {
    getAvatarMapper(): IMapper<AvatarData, Avatar> {
        return new AvatarMapper();
    }

    getAvatarFlowViewMapper(): IMapper<AvatarFlowViewData, AvatarFlowView> {
        return new AvatarFlowViewMapper();
    }

    getAvatarCategoryViewMapper(): IMapper<AvatarCategoryViewData, AvatarCategoryView> {
        return new AvatarCategoryViewMapper();
    }

    getAvatarCategoryViewDataMapper(): IMapper<AvatarCategoryView, AvatarCategoryViewData>{
        return new AvatarCategoryViewDataMapper();
    }

    getAvatarFlowMapper(): IMapper<AvatarFlowData, AvatarFlow> {
        return new AvatarFlowMapper();
    }

    getAvatarCategoryInputDataMapper(): IMapper<AvatarCategoryInputs, AvatarCategoryInputData> {
        return new AvatarCategoryInputDataMapper();
    }

    getAvatarFlowInputDataMapper(): IMapper<AvatarFlowInputs, AvatarFlowInputData> {
        return new AvatarFlowInputDataMapper();
    }
}