import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarFlow from "../entity/AvatarFlow";
import AvatarFlowView from "../entity/view/AvatarFlowView";
import IGetAvatarFlowEditGateway from "../gateway/IGetFlowEditGateway";
import { GetAvatarsInteractorError } from "./GetAvatarsUseCase";

export class GetAvatarFlowEditInteractorError extends Error {
    constructor(message: string) {
        super(`[GetFlowEditUseCase] Error - ${message}`)
    }
}

export interface IGetAvatarFlowEditInput {
    getAvatarFlowEdit(version: string, categoryId: string, flowId: string): Promise<void>;
}

export interface IGetAvatarFlowEditOutput {
    displaySuccessResponse(flowView: AvatarFlowView, flow: AvatarFlow): void;
    displayErrorResponse(error: Error): void;
}

export class GetAvatarFlowEditInteractor implements IGetAvatarFlowEditInput {
    constructor(
        private _output: IGetAvatarFlowEditOutput,
        private _gateway: IGetAvatarFlowEditGateway
    ) { }

    public async getAvatarFlowEdit(version: string, categoryId: string, flowId: string): Promise<void> {
        try {
            await this.interact(StringId.create(version), StringId.create(categoryId), StringId.create(flowId));
        } catch (e) {
            this._output.displayErrorResponse(new GetAvatarsInteractorError(e.message))
        }
    }

    private async interact(version: StringId, categoryId: StringId, flowId: StringId) {
        const flow = await this._gateway.getAvatarFlow(version, categoryId, flowId);
        const flowView = await this._gateway.getAvatarFlowView(version, categoryId, flowId);

        this._output.displaySuccessResponse(flowView, flow);
    }
}