import { combineReducers } from "redux";
import baseReducer from "./base/BaseReducer";
import signInReducer from "./signIn/SignInReducer";
import signOutReducer from "./signOut/SignOutReducer";
import userReducer from "./user/UserReducer";
import userListReducer from "./userList/UserListReducer";
import utilityReducer from "./utility/UtilityReducer";
import utilityListReducer from './utilityList/UtilityListReducer';
import standardProfileListReducer from './standardProfileList/StandardProfileListReducer';
import mappingListReducer from './mappingList/MappingListReducer';
import avatarReducer from './avatar/AvatarReducer';
import avatarEditFlowReducer from './avatarEdit/AvatarEditReducer';
import avatarCategoryReducer from "./avatar/category/AvatarCategoryReducer";
import avatarFlowReducer from './avatar/flow/AvatarFlowReducer';

const RootReducer = combineReducers({
    baseReducer,
    signInReducer,
    signOutReducer,
    utilityReducer,
    utilityListReducer,
    userListReducer,
    userReducer,
    standardProfileListReducer,
    mappingListReducer,
    avatarReducer,
    avatarEditFlowReducer,
    avatarCategoryReducer,
    avatarFlowReducer
})

export default RootReducer;