import StringId from "../../../domain/base/valueObject/uniqueEntityID/StringId";
import AvatarFlowView from "../../../domain/modules/avatar/entity/view/AvatarFlowView";
import { IAddAvatarFlowOutput } from "../../../domain/modules/avatar/useCase/AddAvatarFlowUseCase";
import { ADD_AVATAR_FLOW_SUCCESS } from "../../redux/avatar/flow/AvatarFlowActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarFlowPresentation from "./presentation/AvatarFlowPresentation";

export default class AddAvatarFlowPresenter extends ReduxPresenter implements IAddAvatarFlowOutput {
    constructor(dispatch: IReduxDispatch, private flowPresentation: AvatarFlowPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse(flow: AvatarFlowView, categoryId: StringId): void {
        const flowViewVM = this.flowPresentation.presentFlowView(flow);
        const flowVM = this.flowPresentation.presentFlowFromFlowView(flow);

        this.dispatch({
            type: ADD_AVATAR_FLOW_SUCCESS,
            payload: {
                categoryId: categoryId.getId(),
                flow: flowVM,
                flowView: flowViewVM
            }
        })
    }
}