import Avatar from "../entity/Avatar";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class AvatarListError extends Error {
    constructor(message: string) {
        super(`[AvatarList] Error - ${message}`)
    }
}

interface AvatarListProps {
    avatars: Avatar[]
}

export default class AvatarList extends ValueObject<AvatarListProps> {
    get avatars(): Avatar[] {
        return this.props.avatars
    }

    private constructor(props: AvatarListProps) {
        super(props)
    }

    public static create(avatars: Avatar[]): AvatarList {
        if (!avatars)
            throw new AvatarListError('Avatar list must not be undefined')        
        return new AvatarList({ avatars })
    }

    public equals(vo: ValueObject<AvatarListProps>): boolean {

        if (this.props.avatars.length !== vo.props.avatars.length)
            return false

        let result = this.props.avatars.filter(a1 => vo.props.avatars.some(a2 => a1.id.isEqual(a2.id)));
       
        return result.length === this.props.avatars.length
    }
}