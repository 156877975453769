import AvatarFlow from "../entity/AvatarFlow";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class AvatarFlowListError extends Error {
    constructor(message: string) {
        super(`[AvatarFlowList] Error - ${message}`)
    }
}

interface AvatarFlowListProps {
    flows: AvatarFlow[]
}

export default class AvatarFlowList extends ValueObject<AvatarFlowListProps> {
    get flows(): AvatarFlow[] {
        return this.props.flows
    }

    private constructor(props: AvatarFlowListProps) {
        super(props)
    }

    public static create(flows: AvatarFlow[]): AvatarFlowList {
        if (!flows)
            throw new AvatarFlowListError('Avatar flow list must not be undefined')        
        return new AvatarFlowList({ flows })
    }

    public equals(vo: ValueObject<AvatarFlowListProps>): boolean {

        if (this.props.flows.length !== vo.props.flows.length)
            return false

        let result = this.props.flows.filter(f1 => vo.props.flows.some(f2 => f1.id.isEqual(f2.id)));
       
        return result.length === this.props.flows.length
    }
}