import Entity from "../../../../base/entity/Entity";
import NotEmptyString from "../../../../base/valueObject/NotEmptyString";
import StringId from "../../../../base/valueObject/uniqueEntityID/StringId";
import MultilingualString from "../../valueObject/MultilingualString";


export class AvatarCategoryViewError extends Error {
    constructor(message: string) {
        super(`[AvatarCategoryViewError] Error - ${message}`);
    }
}

interface AvatarCategoryViewProps {
    title: MultilingualString;
}

export type AvatarCategoryViewUpdatableData = {
    en?: string,
    de?: string,
    fr?: string,
    it?: string
}

export default class AvatarCategoryView extends Entity<AvatarCategoryViewProps, string> {
    get id(): StringId {
        return StringId.create(this._id.getId());
    }

    get title(): MultilingualString {
        return this.props.title;
    }

    public equals(object: AvatarCategoryView): boolean {
        return super.equals(object) && this.props.title.equals(object.title)

    }

    public update(data: AvatarCategoryViewUpdatableData): AvatarCategoryView {
        try {
            console.log( data.en ? NotEmptyString.create(data.en) : this.title.en)
            return new AvatarCategoryView({
                title: MultilingualString.create(
                    data.en ? NotEmptyString.create(data.en) : this.title.en,
                    data.de ? NotEmptyString.create(data.de) : this.title.de,
                    data.fr ? NotEmptyString.create(data.fr) : this.title.fr,
                    data.it ? NotEmptyString.create(data.it) : this.title.it
                )
            }, this.id)
        } catch (e) {
            throw new AvatarCategoryViewError(e.message)
        }
    }
}