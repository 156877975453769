import { AvatarCategoryViewViewModel, AvatarFlowViewModel, AvatarFlowViewViewModel } from "../../presenter/avatar/viewModel/AvatarViewModels";

export const SET_AVATAR_FLOW_EDIT = "SET_AVATAR_FLOW_EDIT";
export const GET_AVATAR_FLOW_EDIT_FAIL = "GET_AVATAR_FLOW_EDIT_FAIL";
export const AVATAR_FLOW_EDIT_INPUTS = "AVATAR_FLOW_EDIT_INPUTS";
export const GET_AVATAR_FLOW_BY_ID_SUCCESS = "GET_AVATAR_FLOW_BY_ID_SUCCESS";
export const GET_AVATAR_FLOW_BY_ID_FAIL = "GET_AVATAR_FLOW_BY_ID_FAIL";

export type AvatarFlowEditInputsModel = {
    field: string;
    value: string | boolean;
}

interface SetAvatarFlowEdit {
    type: typeof SET_AVATAR_FLOW_EDIT,
    payload: {
        flowInfo: AvatarFlowViewModel;
        flow: AvatarFlowViewViewModel;
    }
}

interface GetAvatarFlowEditFail {
    type: typeof GET_AVATAR_FLOW_EDIT_FAIL;
    payload: {
        errorMessage: string
    }
}

interface AvatarFlowEditInputs {
    type: typeof AVATAR_FLOW_EDIT_INPUTS,
    payload: AvatarFlowEditInputsModel;
}

interface GetAvatarFlowByIdSuccess {
    type: typeof GET_AVATAR_FLOW_BY_ID_SUCCESS,
    payload: {
        flow: AvatarFlowViewViewModel;
        flowInfo: AvatarFlowViewModel;
        category: AvatarCategoryViewViewModel;
    }
}

interface GetAvatarFlowByIdFail {
    type: typeof GET_AVATAR_FLOW_BY_ID_FAIL;
    payload: {
        errorMessage: string
    }
}

export type AvatarFlowEditActionTypes = SetAvatarFlowEdit | GetAvatarFlowEditFail | AvatarFlowEditInputs | GetAvatarFlowByIdSuccess | GetAvatarFlowByIdFail;