import StringId from "../../../domain/base/valueObject/uniqueEntityID/StringId";
import { DELETE_AVATAR_CATEGORY_SUCCESS } from "../../redux/avatar/AvatarActionTypes";
import { BASE_SNACK_OPEN } from "../../redux/base/BaseActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";

export default class DeleteAvatarCategoryPresenter extends ReduxPresenter implements IBasicOutput<StringId> {
    constructor(dispatch: IReduxDispatch) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse = (categoryId: StringId): void => {
        this.dispatch({
            type: DELETE_AVATAR_CATEGORY_SUCCESS,
            payload: {
                categoryId: categoryId.getId()
            }
        })
        
        this.dispatch({
            type: BASE_SNACK_OPEN,
            payload: {
                snackText: "Category deleted successfully!"
            }
        })
    }
}