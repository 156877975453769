import { AvatarViewModel, AvatarFlowViewViewModel, AvatarCategoryViewViewModel, AvatarFlowLanguage } from "../../presenter/avatar/viewModel/AvatarViewModels";
import { GET_AVATAR_FLOW_BY_ID_FAIL, GET_AVATAR_FLOW_BY_ID_SUCCESS } from "../avatarEdit/AvatarEditActionTypes";
import {
    GET_AVATARS_SUCCESS, GET_AVATARS_FAIL, ADD_AVATAR_CATEGORY_FAIL, ADD_AVATAR_CATEGORY_SUCCESS, AvatarActionTypes,
    CHANGE_AVATAR_LANGUAGE, CHANGE_AVATAR_THEME, RESET_AVATAR_FLOW_PREVIEW, SELECT_AVATAR_VERSION, SET_AVATAR_FLOW_PREVIEW,
    GET_AVATAR_FLOW_PREVIEW_LOADING, GET_AVATAR_CATEGORY_BY_ID_FAIL, GET_AVATAR_CATEGORY_BY_ID_SUCCESS,
    ADD_AVATAR_FLOW_FAIL, ADD_AVATAR_FLOW_SUCCESS, EDIT_AVATAR_CATEGORY_SUCCESS, EDIT_AVATAR_CATEGORY_FAIL,
    DELETE_AVATAR_CATEGORY_FAIL, DELETE_AVATAR_CATEGORY_SUCCESS, AVATAR_LOADING
} from "./AvatarActionTypes";
import { } from "./AvatarActionTypes";

interface AvatarState {
    avatarList: AvatarViewModel[];
    selectedAvatarVersion?: AvatarViewModel;
    selectedFlow?: AvatarFlowViewViewModel;
    selectedCategory?: AvatarCategoryViewViewModel;
    dark: boolean;
    language: AvatarFlowLanguage;
    previewLoading: boolean;
    loading: boolean;
    error?: string;
}

const defaultState: AvatarState = {
    previewLoading: false,
    avatarList: [],
    language: 'en',
    dark: false,
    loading: false,
    error: ''
}

const avatarReducer = (state: AvatarState = defaultState, action: AvatarActionTypes): AvatarState => {
    switch (action.type) {
        case AVATAR_LOADING:
            return { ...state, loading: true }
        case GET_AVATARS_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case GET_AVATARS_SUCCESS:
            const { avatarList } = action.payload
            return {
                ...state,
                loading: false,
                avatarList: action.payload.avatarList,
                selectedAvatarVersion: avatarList[avatarList.length - 1]
            }
        case SET_AVATAR_FLOW_PREVIEW:
            return {
                ...state,
                selectedFlow: action.payload.flow,
                selectedCategory: action.payload.category,
                previewLoading: false
            }
        case GET_AVATAR_FLOW_PREVIEW_LOADING:
            return {
                ...state,
                previewLoading: true
            }
        case RESET_AVATAR_FLOW_PREVIEW:
            return {
                ...state,
                selectedFlow: undefined,
                selectedCategory: undefined,
            };
        case CHANGE_AVATAR_THEME:
            return { ...state, dark: !state.dark };
        case SELECT_AVATAR_VERSION:
            return { ...state, selectedAvatarVersion: action.payload.avatar };
        case CHANGE_AVATAR_LANGUAGE:
            return { ...state, language: action.payload.language };
        case GET_AVATAR_FLOW_BY_ID_SUCCESS:
            return {
                ...state,
                selectedFlow: action.payload.flow,
                selectedCategory: action.payload.category
            };
        case GET_AVATAR_FLOW_BY_ID_FAIL:
            return { ...state, error: action.payload.errorMessage };
        case ADD_AVATAR_CATEGORY_SUCCESS:
            state.selectedAvatarVersion?.categoryList.push(action.payload.category);
            return { ...state, loading: false, selectedCategory: action.payload.categoryView };
        case ADD_AVATAR_CATEGORY_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage };
        case GET_AVATAR_CATEGORY_BY_ID_SUCCESS:
            return { ...state, loading: false, selectedCategory: action.payload.categoryView };
        case GET_AVATAR_CATEGORY_BY_ID_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage };
        case ADD_AVATAR_FLOW_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage };
        case ADD_AVATAR_FLOW_SUCCESS:
            state.selectedAvatarVersion?.categoryList.forEach(c => {
                if (c.id === action.payload.categoryId) {
                    c.flowList.push(action.payload.flow)
                }
            })
            return { ...state, loading: false, selectedFlow: action.payload.flowView };
        case EDIT_AVATAR_CATEGORY_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage };
        case EDIT_AVATAR_CATEGORY_SUCCESS:
            const category = action.payload.categoryView
            state.selectedAvatarVersion?.categoryList.forEach(c => {
                if (c.id === category.id) {
                    c.name = category.title.en
                }
            })
            return { ...state, loading: false };
        case DELETE_AVATAR_CATEGORY_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage };
        case DELETE_AVATAR_CATEGORY_SUCCESS:
            const catId = action.payload.categoryId
            state.selectedAvatarVersion?.categoryList.forEach((c, i) => {
                if (c.id === catId) {
                    state.selectedAvatarVersion?.categoryList.splice(i, 1)
                }
            })
            return { ...state, loading: false, selectedCategory: undefined };
        default:
            return state;
    }
}

export default avatarReducer;