import { Dispatch } from 'redux';
import { dependencyContainer } from '../../..';
import { EditUserInteractor } from '../../../domain/modules/user/useCase/EditUserUseCase';
import { GetUserInteractor } from '../../../domain/modules/user/useCase/GetUserUseCase';
import ReduxDispatch from '../../presenter/ReduxDispatch';
import EditUserPresenter from '../../presenter/user/EditUserPresenter';
import GetUserPresenter from '../../presenter/user/GetUserPresenter';
import UserPresentation from '../../presenter/user/presentation/UserPresentation';
import { RootStore } from '../Store';
import { EditUserInputsModel, EDIT_USER_RESET, EDIT_USER_INPUTS, USER_LOADING, EDIT_USER_UTILITY } from './UserActionTypes';


export const GetUser = (id: string) => async (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: USER_LOADING
    })

    const gateway = dependencyContainer.dependency.gatewayFactory.getGetUserGateway();
    new GetUserInteractor(new GetUserPresenter(new ReduxDispatch(dispatch), new UserPresentation()), gateway)
        .getUser(id);
}


export const EditUserChangeInputs = (payload: EditUserInputsModel) => (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_USER_INPUTS,
        payload
    })
}

export const EditUserUtility = (utilityId: string) => (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_USER_UTILITY,
        payload: {
            utilityId
        }
    })
}

export const EditUserReset = () => (dispatch: Dispatch) => {
    dispatch({
        type: EDIT_USER_RESET,
    })
}

export const EditUser = () => (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: USER_LOADING
    })

    const { id, utilityId, zipCode } = getState().userReducer

    new EditUserInteractor(new EditUserPresenter(new ReduxDispatch(dispatch), new UserPresentation()),
        dependencyContainer.dependency.gatewayFactory.getEditUserGateway())
        .editUser(id, { zipCode, utilityId })
}


