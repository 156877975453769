import AvatarCategoryView from "../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import AvatarFlowView from "../../../domain/modules/avatar/entity/view/AvatarFlowView";
import { IGetAvatarFlowPreviewOutput } from "../../../domain/modules/avatar/useCase/GetAvatarFlowPreviewUseCase";
import { SET_AVATAR_FLOW_PREVIEW } from "../../redux/avatar/AvatarActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarCategoryPresentation from "./presentation/AvatarCategoryPresentation";
import AvatarFlowPresentation from "./presentation/AvatarFlowPresentation";

export default class AvatarFlowPreviewPresenter extends ReduxPresenter implements IGetAvatarFlowPreviewOutput {
    constructor(dispatch: IReduxDispatch, 
        private flowViewPresentation: AvatarFlowPresentation,
        private categoryViewPresentation: AvatarCategoryPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse(flowView: AvatarFlowView, categoryView: AvatarCategoryView): void {
        const flowVM = this.flowViewPresentation.presentFlowView(flowView);
        const categoryVM = this.categoryViewPresentation.presentCategoryView(categoryView);

        this.dispatch({
            type: SET_AVATAR_FLOW_PREVIEW,
            payload: {
                flow: flowVM,
                category: categoryVM
            }
        })
    }
}