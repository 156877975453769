import { AvatarFlowActionTypes, ADD_AVATAR_FLOW_CHANGE_INPUTS, ADD_AVATAR_FLOW_INPUT_ERROR, ADD_AVATAR_FLOW_RESET, ADD_AVATAR_FLOW_ERROR } from "./AvatarFlowActionTypes"

interface AvatarFlowState {
    en: string;
    de: string;
    fr: string;
    it: string;
    enError: boolean;
    deError: boolean;
    frError: boolean;
    itError: boolean;
    error?: string;
}

const defaultState: AvatarFlowState = {
    en: "",
    de: "",
    fr: "",
    it: "",
    enError: false,
    deError: false,
    frError: false,
    itError: false
}

const avatarFlowReducer = (state: AvatarFlowState = defaultState, action: AvatarFlowActionTypes): AvatarFlowState => {
    switch (action.type) {
        case ADD_AVATAR_FLOW_CHANGE_INPUTS:
            return { ...state, [action.payload.field]: action.payload.value }
        case ADD_AVATAR_FLOW_INPUT_ERROR:
            return { ...state, [action.payload.field]: action.payload.value }
        case ADD_AVATAR_FLOW_ERROR:
            return { ...state, error: action.payload.errorMessage }
        case ADD_AVATAR_FLOW_RESET:
            return { ...defaultState }
        default:
            return state;
    }
}

export default avatarFlowReducer;