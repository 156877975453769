import { Dispatch } from "redux";
import { dependencyContainer } from "../../../..";
import { AddAvatarFlowInteractor } from "../../../../domain/modules/avatar/useCase/AddAvatarFlowUseCase";
import AddAvatarFlowPresenter from "../../../presenter/avatar/AddAvatarFlowPresenter";
import AvatarFlowPresentation from "../../../presenter/avatar/presentation/AvatarFlowPresentation";
import ReduxDispatch from "../../../presenter/ReduxDispatch";
import { RootStore } from "../../Store";
import { AVATAR_LOADING } from "../AvatarActionTypes";
import { AddFlowErrorModel, AddFlowInputsModel, ADD_AVATAR_FLOW_CHANGE_INPUTS, ADD_AVATAR_FLOW_INPUT_ERROR, ADD_AVATAR_FLOW_RESET } from "./AvatarFlowActionTypes";

export const AddFlowChangeInputs = (payload: AddFlowInputsModel) => (dispatch: Dispatch) => {
    dispatch({
        type: ADD_AVATAR_FLOW_CHANGE_INPUTS,
        payload
    })
}

export const AddFlowReset = () => (dispatch: Dispatch) => {
    dispatch({ type: ADD_AVATAR_FLOW_RESET })
}

export const AddFlowInputsError = (payload: AddFlowErrorModel) => (dispatch: Dispatch) => {
    dispatch({
        type: ADD_AVATAR_FLOW_INPUT_ERROR,
        payload
    })
}

export const AddAvatarFlow = (version: string, category: string) => (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({ type: AVATAR_LOADING })

    const { en, de, fr, it } = getState().avatarFlowReducer;

    new AddAvatarFlowInteractor(new AddAvatarFlowPresenter(new ReduxDispatch(dispatch), new AvatarFlowPresentation()),
        dependencyContainer.dependency.gatewayFactory.getAddAvatarFlowGateway())
        .addFlow(version, category, { en, de, fr, it })
}