import { Dispatch } from 'redux';
import { dependencyContainer } from '../../..';

import { GetUsersInteractor } from '../../../domain/modules/user/useCase/GetUsersUseCase';
import GetUsersPresenter from '../../presenter/user/GetUsersPresenter';
import ReduxDispatch from '../../presenter/ReduxDispatch';
import { RootStore } from '../Store';
import UserPresentation from '../../presenter/user/presentation/UserPresentation';
import { GET_USERS_LOADING } from './UserListActionTypes';


export const GetUsers = () => async (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({
        type: GET_USERS_LOADING
    });

    const gateway = dependencyContainer.dependency.gatewayFactory.getGetUsersGateway();
    new GetUsersInteractor(new GetUsersPresenter(new ReduxDispatch(dispatch), new UserPresentation()), gateway)
        .getUsers();
}
