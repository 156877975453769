import IGetAvatarsGateway from "../gateway/IGetAvatarsGateway"
import AvatarList from "../valueObject/AvatarList"

export class GetAvatarsInteractorError extends Error {
    constructor(message: string) {
        super(`[GetAvatarsUseCase] Error - ${message}`)
    }
}

export interface IGetAvatarsInput {
    getAvatars(): Promise<void>;
}

export interface IGetAvatarsOutput {
    displaySuccessResponse(avatar: AvatarList): void;
    displayErrorResponse(error: Error): void;
}

export class GetAvatarsInteractor implements IGetAvatarsInput {
    constructor(
        private _output: IGetAvatarsOutput,
        private _gateway: IGetAvatarsGateway
    ) { }

    public async getAvatars(): Promise<void> {
        try {
            await this.interact()
        } catch (e) {
            this._output.displayErrorResponse(new GetAvatarsInteractorError(e.message))
        }
    }

    private async interact() {
        const avatar = await this._gateway.getAvatars()

        this._output.displaySuccessResponse(avatar)
    }

}