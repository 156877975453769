import { BASE_ERROR } from "../../redux/base/BaseActionTypes";
import { ADD_AVATAR_CATEGORY_FAIL, ADD_AVATAR_FLOW_FAIL, DELETE_AVATAR_CATEGORY_FAIL, EDIT_AVATAR_CATEGORY_FAIL, GET_AVATARS_FAIL, GET_AVATAR_FLOW_PREVIEW_FAIL } from "../../redux/avatar/AvatarActionTypes";
import { ErrorCodes } from "../error/ErrorCodes";
import ErrorProcessor from "../error/ErrorProcessor";
import { GET_AVATAR_FLOW_BY_ID_FAIL, GET_AVATAR_FLOW_EDIT_FAIL } from "../../redux/avatarEdit/AvatarEditActionTypes";
import { GET_AVATAR_CATEGORY_BY_ID_FAIL } from "../../redux/avatar/AvatarActionTypes";


export default class AvatarErrors {
    private _processor = new ErrorProcessor(
        this.format("Internal Server Error", BASE_ERROR),
        [{
            condition: (err) => err.message.includes('[getAvatars]'),
            value: () => this.format(ErrorCodes.GET_AVATARS_FAIL, GET_AVATARS_FAIL)
        },
        {
            condition: (err) => err.message.includes('getAvatarFlow'),
            value: () => this.format(ErrorCodes.GET_AVATAR_FLOW_EDIT_FAIL, GET_AVATAR_FLOW_EDIT_FAIL)
        },
        {
            condition: (err) => err.message.includes('[getAvatarFlowView]'),
            value: () => this.format(ErrorCodes.GET_AVATAR_FLOW_PREVIEW_FAIL, GET_AVATAR_FLOW_PREVIEW_FAIL)
        },
        {
            condition: (err) => err.message.includes('[getFlowById]'),
            value: () => this.format(ErrorCodes.GET_AVATAR_FLOW_BY_ID_FAIL, GET_AVATAR_FLOW_BY_ID_FAIL)
        },
        {
            condition: (err) => err.message.includes('[addCategory]'),
            value: () => this.format(ErrorCodes.ADD_AVATAR_CATEGORY_FAIL, ADD_AVATAR_CATEGORY_FAIL)
        },
        {
            condition: (err) => err.message.includes('[getAvatarCategoryById]'),
            value: () => this.format(ErrorCodes.GET_AVATAR_CATEGORY_BY_ID_FAIL, GET_AVATAR_CATEGORY_BY_ID_FAIL)
        },
        {
            condition: (err) => err.message.includes('[addFlow]'),
            value: () => this.format(ErrorCodes.ADD_AVATAR_FLOW_FAIL, ADD_AVATAR_FLOW_FAIL)
        },
        {
            condition: (err) => err.message.includes('[editAvatarCategory]'),
            value: () => this.format(ErrorCodes.EDIT_AVATAR_CATEGORY_FAIL, EDIT_AVATAR_CATEGORY_FAIL)
        },
        {
            condition: (err) => err.message.includes('[deleteAvatarCategory]'),
            value: () => this.format(ErrorCodes.DELETE_AVATAR_CATEGORY_FAIL, DELETE_AVATAR_CATEGORY_FAIL)
        }]
    )

    get processor() {
        return this._processor;
    }

    private format(message: string, actionType: string) {
        return { message, actionType };
    }
}