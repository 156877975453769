import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import AvatarCategoryView from "../../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import MultilingualString from "../../../../domain/modules/avatar/valueObject/MultilingualString";
import { AvatarCategoryViewData } from "../../../dataSource/avatarDataSource/IAvatarDataSource";
import IMapper from "../../../IMapper";

export default class AvatarCategoryViewMapper implements IMapper<AvatarCategoryViewData, AvatarCategoryView> {
    map(input: AvatarCategoryViewData): AvatarCategoryView {
        const { text } = input
        return new AvatarCategoryView({
            title: MultilingualString.create(
                NotEmptyString.create(text.en),
                NotEmptyString.create(text.de),
                NotEmptyString.create(text.fr),
                NotEmptyString.create(text.it)
            )
        }, StringId.create(input.categoryId))
    }
}