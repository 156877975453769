import { Grid, Typography } from "@material-ui/core";
import Linkify from "react-linkify";

interface AvatarTextChatProps {
  text: string;
  cellClass: string;
  cellTriangleClass: string;
  linkDecorator: (href: string, key: string) => {};
}

export default function AvatarTextChat(props: AvatarTextChatProps) {
  const { text, cellClass, cellTriangleClass, linkDecorator } = props;

  return (
    <Grid>
      <Grid className={cellTriangleClass}>
      </Grid>
      <Grid className={cellClass}>
        <Typography>
          <Linkify componentDecorator={linkDecorator}>{text}</Linkify>
        </Typography>
      </Grid>
    </Grid>
  );
}

