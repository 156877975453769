import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCategoryView from "../entity/view/AvatarCategoryView";
import { IGetAvatarCategoryByIdGateway } from "../gateway/IGetAvatarCategoryByIdGateway";

export class GetAvatarCategoryByIdError extends Error {
    constructor(message: string) {
        super(`[getAvatarCategoryById] Interactor Error - ${message}`);
    }
}

export interface IGetAvatarCategoryByIdInput {
    getAvatarCategoryById(version: string, categoryId: string): Promise<void>;
}


export interface IGetAvatarCategoryByIdOutput {
    displaySuccessResponse(category: AvatarCategoryView): void;
    displayErrorResponse(error: Error): void;
}

export class GetAvatarCategoryByIdInteractor implements IGetAvatarCategoryByIdInput {
    constructor(
        private _output: IGetAvatarCategoryByIdOutput,
        private _gateway: IGetAvatarCategoryByIdGateway
    ) { }

    public async getAvatarCategoryById(version: string, categoryId: string): Promise<void> {
        try {
            await this.interact(StringId.create(version), StringId.create(categoryId));
        } catch (e) {
            this._output.displayErrorResponse(new GetAvatarCategoryByIdError(e.message))
        }
    }

    private async interact(version: StringId, categoryId: StringId) {
        this._output.displaySuccessResponse(await this._gateway.getAvatarCategoryById(version, categoryId));
    }
}