import AvatarCategoryView from "../../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import IMapper from "../../../../infrastructure/IMapper";
import { AvatarCategoryViewModel, AvatarCategoryViewViewModel } from "../viewModel/AvatarViewModels";


export default class AvatarCategoryPresentation implements IMapper<AvatarCategoryView, AvatarCategoryViewViewModel> {

    presentCategoryView(categoryView: AvatarCategoryView): AvatarCategoryViewViewModel {
        return this.map(categoryView)
    }

    map(input: AvatarCategoryView): AvatarCategoryViewViewModel {
        const { id, title } = input;
        return {
            id: id.getId(),
            title: {
                en: title.en.value,
                de: title.de.value,
                fr: title.fr.value,
                it: title.it.value
            }
        }
    }

    // view entity -> (not view) viewModel
    // needed in reducer for side menu
    presentCategory(categoryView: AvatarCategoryView): AvatarCategoryViewModel {
        const { id, title } = categoryView;
        return {
            id: id.getId(),
            name: title.en.value,
            flowList: []
        }
    }
}