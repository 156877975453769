import { AvatarCategoryViewModel, AvatarCategoryViewViewModel, AvatarFlowLanguage, AvatarFlowViewModel, AvatarFlowViewViewModel, AvatarViewModel } from "../../presenter/avatar/viewModel/AvatarViewModels";
import { GET_AVATAR_FLOW_BY_ID_FAIL, GET_AVATAR_FLOW_BY_ID_SUCCESS } from "../avatarEdit/AvatarEditActionTypes";

export const GET_AVATARS_FAIL = "GET_AVATARS_FAIL";
export const AVATAR_LOADING = "AVATAR_LOADING";
export const GET_AVATARS_SUCCESS = "GET_AVATARS_SUCCESS";
export const NEW_AVATAR_CATEGORY = "NEW_AVATAR_CATEGORY";
export const NEW_AVATAR_FLOW = "NEW_AVATAR_FLOW";
export const CHANGE_AVATAR_THEME = "CHANGE_AVATAR_THEME";
export const SELECT_AVATAR_VERSION = "SELECT_AVATAR_VERSION";
export const CHANGE_AVATAR_LANGUAGE = "CHANGE_AVATAR_LANGUAGE";
export const SET_AVATAR_FLOW_PREVIEW = "SET_AVATAR_FLOW_PREVIEW";
export const GET_AVATAR_FLOW_PREVIEW_LOADING = "GET_AVATAR_FLOW_PREVIEW_LOADING";
export const GET_AVATAR_FLOW_PREVIEW_FAIL = "GET_AVATAR_FLOW_PREVIEW_FAIL";
export const RESET_AVATAR_FLOW_PREVIEW = "RESET_AVATAR_FLOW_PREVIEW";

export const ADD_AVATAR_CATEGORY_SUCCESS = "ADD_AVATAR_CATEGORY_SUCCESS";
export const ADD_AVATAR_CATEGORY_FAIL = "ADD_AVATAR_CATEGORY_FAIL";

export const ADD_AVATAR_FLOW_SUCCESS = "ADD_AVATAR_FLOW_SUCCESS";
export const ADD_AVATAR_FLOW_FAIL = "ADD_AVATAR_FLOW_FAIL";

export const GET_AVATAR_CATEGORY_BY_ID_SUCCESS = "GET_AVATAR_CATEGORY_BY_ID_SUCCESS"
export const GET_AVATAR_CATEGORY_BY_ID_FAIL = "GET_AVATAR_CATEGORY_BY_ID_FAIL"

export const EDIT_AVATAR_CATEGORY_SUCCESS = "EDIT_AVATAR_CATEGORY_SUCCESS"
export const EDIT_AVATAR_CATEGORY_FAIL = "EDIT_AVATAR_CATEGORY_FAIL"

export const DELETE_AVATAR_CATEGORY_SUCCESS = "DELETE_AVATAR_CATEGORY_SUCCESS"
export const DELETE_AVATAR_CATEGORY_FAIL = "DELETE_AVATAR_CATEGORY_FAIL"

interface AvatarLoading {
    type: typeof AVATAR_LOADING,
}

interface GetAvatarsFail {
    type: typeof GET_AVATARS_FAIL,
    payload: {
        errorMessage: string;
    };
}

interface GetAvatarsSuccess {
    type: typeof GET_AVATARS_SUCCESS,
    payload: {
        avatarList: AvatarViewModel[]
    };
}

interface SetAvatarFlowPreview {
    type: typeof SET_AVATAR_FLOW_PREVIEW
    payload: {
        flow: AvatarFlowViewViewModel,
        category: AvatarCategoryViewViewModel
    };
}

interface GetAvatarFlowPreviewLoading {
    type: typeof GET_AVATAR_FLOW_PREVIEW_LOADING;
}

interface GetAvatarFlowPreviewFail {
    type: typeof GET_AVATAR_FLOW_PREVIEW_FAIL;
    payload: {
        errorMessage: string
    }
}

interface ResetAvatarFlowPreview {
    type: typeof RESET_AVATAR_FLOW_PREVIEW;
}

interface NewAvatarCategory {
    type: typeof NEW_AVATAR_CATEGORY
}

interface ChangeAvatarTheme {
    type: typeof CHANGE_AVATAR_THEME
}

interface SelectAvatarVersion {
    type: typeof SELECT_AVATAR_VERSION,
    payload: {
        avatar: AvatarViewModel
    }
}

interface ChangeAvatarLanguage {
    type: typeof CHANGE_AVATAR_LANGUAGE,
    payload: {
        language: AvatarFlowLanguage
    }
}

interface GetAvatarFlowByIdSuccess {
    type: typeof GET_AVATAR_FLOW_BY_ID_SUCCESS,
    payload: {
        flow: AvatarFlowViewViewModel,
        flowInfo: AvatarFlowViewModel,
        category: AvatarCategoryViewViewModel
    }
}

interface GetAvatarFlowByIdFail {
    type: typeof GET_AVATAR_FLOW_BY_ID_FAIL,
    payload: {
        errorMessage: string
    }
}

interface AddAvatarCategorySuccess {
    type: typeof ADD_AVATAR_CATEGORY_SUCCESS,
    payload: {
        category: AvatarCategoryViewModel,
        categoryView: AvatarCategoryViewViewModel
    }
}

interface AddAvatarCategoryFail {
    type: typeof ADD_AVATAR_CATEGORY_FAIL,
    payload: {
        errorMessage: string
    }
}

interface GetAvatarCategoryByIdSuccess {
    type: typeof GET_AVATAR_CATEGORY_BY_ID_SUCCESS,
    payload: {
        categoryView: AvatarCategoryViewViewModel
    }
}

interface GetAvatarCategoryByIdFail {
    type: typeof GET_AVATAR_CATEGORY_BY_ID_FAIL,
    payload: {
        errorMessage: string
    }
}

interface AddAvatarFlowSuccess {
    type: typeof ADD_AVATAR_FLOW_SUCCESS,
    payload: {
        categoryId: string,
        flow: AvatarFlowViewModel,
        flowView: AvatarFlowViewViewModel
    }
}

interface AddAvatarFlowFail {
    type: typeof ADD_AVATAR_FLOW_FAIL,
    payload: {
        errorMessage: string
    }
}

interface EditAvatarCategorySuccess {
    type: typeof EDIT_AVATAR_CATEGORY_SUCCESS,
    payload: {
        categoryView: AvatarCategoryViewViewModel
    }
}

interface EditAvatarCategoryFail {
    type: typeof EDIT_AVATAR_CATEGORY_FAIL,
    payload: {
        errorMessage: string
    }
}

interface DeleteAvatarCategorySuccess {
    type: typeof DELETE_AVATAR_CATEGORY_SUCCESS,
    payload: {
        categoryId: string
    }
}

interface DeleteAvatarCategoryFail {
    type: typeof DELETE_AVATAR_CATEGORY_FAIL,
    payload: {
        errorMessage: string
    }
}

export type AvatarActionTypes =
    AvatarLoading | GetAvatarsFail | GetAvatarsSuccess | SelectAvatarVersion |
    NewAvatarCategory | ChangeAvatarTheme | SetAvatarFlowPreview | GetAvatarFlowPreviewFail |
    GetAvatarFlowPreviewLoading | ChangeAvatarLanguage | ResetAvatarFlowPreview | GetAvatarFlowByIdSuccess |
    GetAvatarFlowByIdFail | AddAvatarCategorySuccess | AddAvatarCategoryFail | GetAvatarCategoryByIdSuccess |
    GetAvatarCategoryByIdFail | AddAvatarFlowSuccess | AddAvatarFlowFail | EditAvatarCategorySuccess |
    EditAvatarCategoryFail | DeleteAvatarCategorySuccess | DeleteAvatarCategoryFail;
