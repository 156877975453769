import Entity from "../../../../base/entity/Entity";
import StringId from "../../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCell from "../../cell/AvatarCell";
import AvatarImage from "../../cell/AvatarImage";
import AvatarText from "../../cell/AvatarText";
import UserOptions from "../../cell/UserOptions";
import MultilingualString from "../../valueObject/MultilingualString";

export class AvatarFlowViewError extends Error {
    constructor(message: string) {
        super(`[AvatarFlowViewError] Error - ${message}`);
    }
}

interface AvatarFlowViewProps {
    title: MultilingualString;
    isRootFlow: boolean;
    cells: AvatarCell[];
    comment?: string;
    triggerId?: StringId;
}

export default class AvatarFlowView extends Entity<AvatarFlowViewProps, string> {
    get id(): StringId {
        return StringId.create(this._id.getId());
    }

    get title(): MultilingualString {
        return this.props.title;
    }

    get isRootFlow(): boolean {
        return this.props.isRootFlow;
    }

    get cells(): Array<UserOptions | AvatarText | AvatarImage> {
        return this.props.cells;
    }

    get comment(): string | undefined {
        return this.props.comment;
    }

    get triggerId(): StringId | undefined {
        return this.props.triggerId;
    }

    public equals(object: AvatarFlowView): boolean {
        if(!super.equals(object)) 
            return false

        if (this.props.cells.length !== object.cells.length)
            return false

        const result = this.props.cells.filter(c1 => {
                return object.props.cells.some(c2 => c1.equals(c2))
        });
       
        return result.length === this.props.cells.length
    }
}