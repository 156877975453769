import { Card, makeStyles, Theme, FormControl, Select, MenuItem, Grid, AppBar, Tabs, Tab, Container, TextField, IconButton } from "@material-ui/core";
import { Delete, DragHandle } from "@material-ui/icons";
import { DropzoneArea } from "material-ui-dropzone";
import { useState } from "react";

interface RowProps {
    index: number;
    removeRow: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(0)
    },
    topContainer: {
        padding: theme.spacing(1, 1)
    },
    contentContainer: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(0, 1),
        width: "100%",
        flexGrow: 1
    },
    formControl: {
        padding: theme.spacing(0, 0, 1)
    },
    nagiationContainer: {
        position: "relative",
        width: "100%",
        margin: theme.spacing(0, 0, 6)
    },
    navigation: {
        boxShadow: 'none'
    },
    tabs: {
        color: theme.palette.primary.main,
        position: "absolute",
        width: "100%"
    },
    indicator: {
        background: theme.palette.primary.main
    },
    textField: {
        margin: theme.spacing(1, 0)
    },
    closeButton: {
        float: "right",
        color: theme.palette.secondary.dark
    }
}));

const tabLabels = ["En", "De", "Fr", "It"];

export default function Row(props: RowProps) {
    const classes = useStyles();
    const [state, setState] = useState('Avatar text');
    const [state2, setState2] = useState('Go to');
    const [value, setValue] = useState(0);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setState(event.target.value as string);
    };

    const handleChange2 = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        setState2(event.target.value as string);
    };

    const remove = () => {
        props.removeRow(props.index);
    };

    const handleChangeAppBar = (event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Card raised={false} className={classes.root}>
            <Container maxWidth={false} disableGutters={true} className={classes.topContainer}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={5}>
                        <FormControl className={classes.formControl} variant="outlined" fullWidth>
                            <Select
                                value={state}
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="Avatar text">Avatar text</MenuItem>
                                <MenuItem value="Avatar image">Avatar image</MenuItem>
                                <MenuItem value="User options">User options</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} />
                    <Grid item xs={1}>
                        <IconButton aria-label="delete" className={classes.closeButton}>
                            <DragHandle />
                        </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="delete" className={classes.closeButton} onClick={remove}>
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
                <AppBar className={classes.navigation} position="relative" color="transparent">
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        alignContent="flex-start"
                    >
                        <Grid item xs={12} md={12} className={classes.nagiationContainer}>
                            <Tabs
                                value={value}
                                onChange={handleChangeAppBar}
                                classes={{ indicator: classes.indicator }}
                                className={classes.tabs}
                                variant="scrollable"
                                scrollButtons="off"
                            >
                                {tabLabels.map((el, i) => (
                                    <Tab label={el} key={i} />
                                ))}
                            </Tabs>
                        </Grid>
                    </Grid>
                </AppBar>
            </Container>

            <Container maxWidth={false} disableGutters={true} className={classes.contentContainer}>
                {state === "Avatar text" ?
                    <TextField
                        id="avatartext"
                        label="Text"
                        name="AvatarText"
                        className={classes.textField}
                        // value={state.description}
                        multiline
                        rows={8}
                        variant="outlined"
                    // onChange={(event) =>
                    //     onChangeInputs("description", event.target.value)
                    // }
                    />
                    :
                    state === "Avatar image" ?
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            // classes={{
                            //     root: imageChanged ? classes.hiddenContent : classes.dragDropCustom
                            // }}
                            dropzoneText={"Click here or drag and drop an image"}
                            // onChange={handleDropzone}
                            filesLimit={1}
                            showAlerts={false}
                            showPreviewsInDropzone={false}
                        />
                        :
                        <Container maxWidth={false} disableGutters={true}>
                            <TextField
                                id="useroptions"
                                label="Option text"
                                name="UserOptions"
                                variant="outlined"
                                className={classes.textField}
                            // value={state.description}
                            // onChange={(event) =>
                            //     onChangeInputs("description", event.target.value)
                            // }
                            />
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item xs={5}>
                                    <FormControl className={classes.formControl} variant="outlined" fullWidth>
                                        <Select
                                            value={state2}
                                            onChange={handleChange2}
                                            displayEmpty
                                        >
                                            <MenuItem value="Go to">go to</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={7} />
                                <Grid item xs={5}>
                                    <TextField
                                        id="flowid"
                                        label="Flow id"
                                        name="FlowId"
                                        value="flow_id"
                                        variant="outlined"
                                        className={classes.textField}
                                    // onChange={(event) =>
                                    //     onChangeInputs("description", event.target.value)
                                    // }
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                }
            </Container>
        </Card>
    );
}