export const ADD_AVATAR_FLOW_CHANGE_INPUTS = "ADD_AVATAR_FLOW_CHANGE_INPUTS";
export const ADD_AVATAR_FLOW_INPUT_ERROR = "ADD_AVATAR_FLOW_INPUT_ERROR";
export const ADD_AVATAR_FLOW_ERROR = "ADD_AVATAR_FLOW_ERROR";
export const ADD_AVATAR_FLOW_SUCCESS = "ADD_AVATAR_FLOW_SUCCESS";
export const ADD_AVATAR_FLOW_RESET = "ADD_AVATAR_FLOW_RESET";

export type AddFlowInputsModel = {
    field: string;
    value: string;
}

export type AddFlowErrorModel = {
    field: string;
    value: boolean;
}

interface AddFlowChangeInputs {
    type: typeof ADD_AVATAR_FLOW_CHANGE_INPUTS,
    payload: AddFlowInputsModel
}

interface AddFlowInputError {
    type: typeof ADD_AVATAR_FLOW_INPUT_ERROR,
    payload: AddFlowErrorModel
}

interface AddFlowError {
    type: typeof ADD_AVATAR_FLOW_ERROR,
    payload: {
        errorMessage: string
    }
}

interface AddFlowReset {
    type: typeof ADD_AVATAR_FLOW_RESET
}

export type AvatarFlowActionTypes = AddFlowChangeInputs | AddFlowInputError | AddFlowError | AddFlowReset;