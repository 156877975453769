import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class MultilingualStringError extends Error {
    constructor(message: string) {
        super(`[MultilingualStringError] Error - ${message}`);
    }
}

interface MultilingualStringProps {
    en: NotEmptyString;
    de: NotEmptyString;
    fr: NotEmptyString;
    it: NotEmptyString;
}

export default class MultilingualString extends ValueObject<MultilingualStringProps> {

    get en(): NotEmptyString {
        return this.props.en;
    }

    get de(): NotEmptyString {
        return this.props.de;
    }

    get fr(): NotEmptyString {
        return this.props.fr;
    }

    get it(): NotEmptyString {
        return this.props.it;
    }

    private constructor(props: MultilingualStringProps) {
        super(props);
    }

    public static create(en: NotEmptyString, de: NotEmptyString, fr: NotEmptyString, it: NotEmptyString): MultilingualString {
        if (!MultilingualString.validate(en) || !MultilingualString.validate(de) || !MultilingualString.validate(fr) || !MultilingualString.validate(it)) {
            throw new MultilingualStringError(`One of provided texts is not valid. MultilingualString - ${en}`);
        } else {
            return new MultilingualString({ en, de, fr, it })
        }
    }

    private static validate(value: NotEmptyString): boolean {
        if (value === undefined || value === null)
            return false;
        return true
    }

    public equals(vo: ValueObject<MultilingualStringProps>): boolean {
        return vo.props.en.equals(this.props.en) &&
            vo.props.de.equals(this.props.de) &&
            vo.props.fr.equals(this.props.fr) &&
            vo.props.it.equals(this.props.it);
    }

    public language(languge: string): string {
        switch (languge) {
            case 'de':
                return this.props.de.value
            case 'fr':
                return this.props.fr.value
            case 'it':
                return this.props.de.value
            default:
                return this.props.en.value
        }
    }
}