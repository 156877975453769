import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { GetAvatarFlowByIdInteractor } from "../../../domain/modules/avatar/useCase/GetAvatarFlowByIdUseCase";
import { GetAvatarFlowEditInteractor } from "../../../domain/modules/avatar/useCase/GetAvatarFlowEditUseCase";
import AvatarFlowEditPresenter from "../../presenter/avatar/AvatarFlowEditPresenter";
import GetAvatarFlowByIdPresenter from "../../presenter/avatar/GetAvatarFlowByIdPresenter";
import AvatarCategoryPresentation from "../../presenter/avatar/presentation/AvatarCategoryPresentation";
import AvatarFlowPresentation from "../../presenter/avatar/presentation/AvatarFlowPresentation";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import { RootStore } from "../Store";
import { AvatarFlowEditInputsModel, AVATAR_FLOW_EDIT_INPUTS } from "./AvatarEditActionTypes";

export const SetAvatarFlowEdit = (version: string, categoryId: string, flowId: string) => (dispatch: Dispatch, getState: () => RootStore) => {
    new GetAvatarFlowEditInteractor(
        new AvatarFlowEditPresenter(
            new ReduxDispatch(dispatch),
            new AvatarFlowPresentation(),
            new AvatarFlowPresentation()),
        dependencyContainer.dependency.gatewayFactory.getGetAvatarFlowEditGateway())
        .getAvatarFlowEdit(version, categoryId, flowId)
}

export const EditAvatarFlowChangeInputs = (payload: AvatarFlowEditInputsModel) => (dispatch: Dispatch) => {
    dispatch({
        type: AVATAR_FLOW_EDIT_INPUTS,
        payload
    })
}

export const GetAvatarFlowById = (version: string, categoryId: string, flowId: string) => (dispatch: Dispatch, getState: () => RootStore) => {
    new GetAvatarFlowByIdInteractor(
        new GetAvatarFlowByIdPresenter(new ReduxDispatch(dispatch),
            new AvatarFlowPresentation(),
            new AvatarCategoryPresentation()),
        dependencyContainer.dependency.gatewayFactory.getGetFlowByIdGateway())
        .getAvatarFlowById(version, categoryId, flowId);

}