import { AvatarCategoryInputs } from "../../../../domain/modules/avatar/useCase/processor/AddAvatarCategoryInputProcessor";
import { AvatarCategoryInputData } from "../../../dataSource/avatarDataSource/IAvatarDataSource";
import IMapper from "../../../IMapper";

export default class AvatarCategoryInputDataMapper implements IMapper<AvatarCategoryInputs, AvatarCategoryInputData> {
    map(input: AvatarCategoryInputs): AvatarCategoryInputData {
       const { text } = input;
       return {
           en: text.en.value,
           de: text.de.value, 
           fr: text.fr.value,
           it: text.it.value
       }
    }
}