import AvatarImage from "../../../../domain/modules/avatar/cell/AvatarImage";
import AvatarText from "../../../../domain/modules/avatar/cell/AvatarText";
import UserOptions from "../../../../domain/modules/avatar/cell/UserOptions";
import AvatarFlow from "../../../../domain/modules/avatar/entity/AvatarFlow";
import AvatarFlowView from "../../../../domain/modules/avatar/entity/view/AvatarFlowView";
import IMapper from "../../../../infrastructure/IMapper";
import { AvatarFlowViewViewModel, UserOptionsViewModel, AvatarTextViewModel, AvatarImageViewModel, AvatarFlowViewModel } from "../viewModel/AvatarViewModels";

export default class AvatarFlowPresentation implements IMapper<AvatarFlowView, AvatarFlowViewViewModel> {

    // view entity -> (not view) viewModel
    // needed in reducer for side menu
    presentFlowFromFlowView(flowView: AvatarFlowView): AvatarFlowViewModel {
        const { id, title } = flowView;
        return {
            id: id.getId(),
            isPublished: false,
            isDrafted: false,
            name: title.en.value
        }
    }

    // entity -> entity viewModel
    presentFlow(flow: AvatarFlow): AvatarFlowViewModel {
        const { id, isDrafted, isPublished, name } = flow;
        return {
            id: id.getId(),
            name: name.value,
            isDrafted: isDrafted,
            isPublished: isPublished,
        }
    }

    // view entity -> viewViewModel
    presentFlowView(flowViewModel: AvatarFlowView): AvatarFlowViewViewModel {
        return this.map(flowViewModel)
    }

    map(input: AvatarFlowView): AvatarFlowViewViewModel {
        const { id, title, isRootFlow, cells, comment, triggerId } = input;
        return {
            id: id.getId(),
            title: {
                en: title.en.value,
                de: title.de.value,
                fr: title.fr.value,
                it: title.it.value
            },
            comment: this.merge("", comment),
            triggerId: this.merge("", triggerId?.getId()),
            isRootFlow: this.merge(false, isRootFlow),
            cells: this.mapCells(cells)
        }
    }

    private mapCells(input: Array<UserOptions | AvatarText | AvatarImage>): Array<UserOptionsViewModel | AvatarTextViewModel | AvatarImageViewModel> {
        const avatarCells = input.map(cell => {
            if (cell instanceof UserOptions) {
                return this.mapUserOptionsViewModel(cell as UserOptions)
            } else if (cell instanceof AvatarImage) {
                return this.mapAvatarImageViewModel(cell as AvatarImage)
            } else
                return this.mapAvatarTextViewModel(cell as AvatarText)
        })
        return avatarCells as Array<UserOptionsViewModel | AvatarTextViewModel | AvatarImageViewModel>
    }

    private mapUserOptionsViewModel(cell: UserOptions): UserOptionsViewModel {
        return {
            id: cell.id.getId(),
            text: {
                en: cell.text.en.value,
                de: cell.text.de.value,
                fr: cell.text.fr.value,
                it: cell.text.it.value
            },
            type: 'userOption',
            goTo: cell.goTo.value,
            typingAnimationLength: cell.typingAnimationLength
        }
    }

    private mapAvatarTextViewModel(cell: AvatarText): AvatarTextViewModel {
        return {
            id: cell.id.getId(),
            text: {
                en: cell.text.en.value,
                de: cell.text.de.value,
                fr: cell.text.fr.value,
                it: cell.text.it.value
            },
            type: 'avatarText',
            typingAnimationLength: cell.typingAnimationLength
        }
    }

    private mapAvatarImageViewModel(cell: AvatarImage): AvatarImageViewModel {
        return {
            id: cell.id.getId(),
            text: {
                en: cell.text.en.value,
                de: cell.text.de.value,
                fr: cell.text.fr.value,
                it: cell.text.it.value
            },
            aspect: cell.aspect,
            type: 'avatarImage',
            typingAnimationLength: cell.typingAnimationLength
        }
    }

    private merge<T>(defaultValue: T, value?: T): T {
        return value === undefined ? defaultValue : value
    }
}