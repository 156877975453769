import { AvatarCategoryActionTypes, ADD_CATEGORY_CHANGE_INPUTS, ADD_CATEGORY_INPUT_ERROR, CATEGORY_INPUT_RESET, SET_CATEGORY_INPUTS } from "./AvatarCategoryActionTypes"

interface AvatarCategoryState {
    en: string;
    de: string;
    fr: string;
    it: string;
    enError: boolean;
    deError: boolean;
    frError: boolean;
    itError: boolean;
    error?: string;
}

const defaultState: AvatarCategoryState = {
    en: "",
    de: "",
    fr: "",
    it: "",
    enError: false,
    deError: false,
    frError: false,
    itError: false,
}

const avatarCategoryReducer = (state: AvatarCategoryState = defaultState, action: AvatarCategoryActionTypes): AvatarCategoryState => {
    switch (action.type) {
        case ADD_CATEGORY_CHANGE_INPUTS:
            return { ...state, [action.payload.field]: action.payload.value }
        case ADD_CATEGORY_INPUT_ERROR:
            return { ...state, [action.payload.field]: action.payload.value }
        case CATEGORY_INPUT_RESET:
            return { ...defaultState }
        case SET_CATEGORY_INPUTS: 
            const {title} = action.payload.categoryView
            return {...defaultState, en: title.en, de: title.de, fr: title.fr, it: title.it }
        default:
            return state;
    }
}

export default avatarCategoryReducer;