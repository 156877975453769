
import NetworkController from "../../network/NetworkController";
import IAvatarDataSource, { AvatarCategoryInputData, AvatarCategoryViewData, AvatarData, AvatarFlowData, AvatarFlowInputData, AvatarFlowViewData, AvatarPreviewData } from "./IAvatarDataSource";
import AvatarMock from "./data/avatarList_json.json"
import AvatarFlowViewPreview from './data/avatarFlowPreview.json'
import AvatarFlowMock from './data/avatarList_json.json'

export class AvatarDataSourceError extends Error {
    constructor(message: string) {
        super(`[AvatarDataSource] Error - ${message}`);
    }
}

export default class AvatarDataSource implements IAvatarDataSource {

    constructor(private nwc: NetworkController) { }

    async getAvatars(): Promise<AvatarData[]> {
        try {
            const avatar: any = await this.nwc.request({
                url: "/api/v1/admin/avatar",
                method: "GET",
                useToken: true
            })

            return avatar.data;
        }
        catch (error) {
            throw new AvatarDataSourceError(`[getAvatars] - ${error.message}`)
        }
    }

    async getAvatarFlow(version: string, categoryId: string, flowId: string): Promise<AvatarFlowData> {
        try {
            const avatar: any = await this.nwc.request({
                url: `/api/v1/admin/avatars/${version}/categories/${categoryId}/${flowId}/edit`,
                method: "GET",
                useToken: true
            })

            return avatar.data.flowInfo;
        }
        catch (error) {
            throw new AvatarDataSourceError(`[getAvatarFlow] - ${error.message}`)
        }
    }

    async getAvatarFlowView(version: string, categoryId: string, flowId: string): Promise<AvatarFlowViewData> {
        try {
            const avatar: any = await this.nwc.request({
                url: `/api/v1/admin/avatars/${version}/categories/${categoryId}/${flowId}/edit`,
                method: "GET",
                useToken: true
            })
            return avatar.data.flow;
        }
        catch (error) {
            throw new AvatarDataSourceError(`[getAvatarFlowView] - ${error.message}`)
        }
    }

    async getFlowById(version: string, categoryId: string, flowId: string): Promise<AvatarPreviewData> {
        try {
            const result: any = await this.nwc.request({
                url: `/api/v1/admin/avatars/${version}/categories/${categoryId}/${flowId}`,
                method: "GET",
                useToken: true
            })
            return result.data;
        } catch (error) {
            throw new AvatarDataSourceError(`[getFlowById] - ${error.message}`)
        }
    }

    async getAvatarCategoryView(version: string, categoryId: string, flowId: string): Promise<AvatarCategoryViewData> {
        try {
            const avatar: any = await this.nwc.request({
                url: `/api/v1/admin/avatars/${version}/categories/${categoryId}/${flowId}/preview`,
                method: "GET",
                useToken: true
            })

            return avatar.data.category;
        }
        catch (error) {
            throw new AvatarDataSourceError(`[getAvatarCategoryView] - ${error.message}`)
        }
    }

    async addCategory(version: string, category: AvatarCategoryInputData): Promise<AvatarCategoryViewData> {
        try {
            const data = new URLSearchParams();
            data.append("en", category.en);
            data.append("de", category.de);
            data.append("fr", category.fr);
            data.append("it", category.it);

            // const avatar: any = await this.nwc.request({
            //     url: `/api/v1/admin/avatars/${version}/categories`,
            //     method: "POST",
            //     useToken: true,
            //     headers: {
            //         "Content-Type": "application/x-www-form-urlencoded"
            //     },
            //     data
            // })

            // return avatar.data.category;
            const mockData = {
                category: {
                    categoryId: "newly Added Category",
                    text: {
                        en: "newly Added Category",
                        de: "newly Added CategoryDE",
                        fr: "newly AddedC ategoryFR",
                        it: "newly Added CategoryIT"
                    }
                }
            }
            return mockData.category;
        }
        catch (error) {
            throw new AvatarDataSourceError(`[addCategory] - ${error.message}`)
        }
    }

    async getAvatarCategoryById(version: string, categoryId: string): Promise<AvatarCategoryViewData> {
        try {
            const result: any = await this.nwc.request({
                url: `/api/v1/admin/avatars/${version}/categories/${categoryId}`,
                method: "GET",
                useToken: true
            })
            return result.data;
        } catch (error) {
            throw new AvatarDataSourceError(`[getAvatarCategoryById] - ${error.message}`)
        }
    }

    async deleteAvatarCategory(version: string, categoryId: string): Promise<void> {
        // try {
        //     await this.nwc.request({
        //         url: `/api/v1/admin/avatars/${version}/categories/${categoryId}`,
        //         method: "DELETE",
        //         useToken: true
        //     })
        //     return
        // } catch (error) {
        //     throw new AvatarDataSourceError(`[deleteAvatarCategory] - ${error.message}`)
        // }
    }

    async editAvatarCategory(version: string, categoryId: string, category: AvatarCategoryViewData): Promise<void> {
        // try {
        //     const {text} = category
        //     const data = new URLSearchParams()
        //     data.append("en", text.en)
        //     data.append("de", text.de)
        //     data.append("fr", text.fr)
        //     data.append("it", text.it)

        //     await this.nwc.request({
        //         url: `/api/v1/admin/avatars/${version}/categories/${categoryId}`,
        //         method: "PUT",
        //         data,
        //         headers: {
        //             "Content-Type": "application/x-www-form-urlencoded"
        //         },
        //         useToken: true

        //     })
        // }
        // catch (error) { 
        //     throw new AvatarDataSourceError(`[editAvatarCategory] - ${error.message}`)
        // }
    }

    async addFlow(version: string, categoryId: string, flow: AvatarFlowInputData): Promise<AvatarFlowViewData> {
        try {
            const data = new URLSearchParams();
            data.append("en", flow.en);
            data.append("de", flow.de);
            data.append("fr", flow.fr);
            data.append("it", flow.it);

            // const avatar: any = await this.nwc.request({
            //     url: `/api/v1/admin/avatars/${version}/categories/${categoryId}`,
            //     method: "POST",
            //     useToken: true,
            //     headers: {
            //         "Content-Type": "application/x-www-form-urlencoded"
            //     },
            //     data
            // })

            // return avatar.data.flow;
            const mockData = {
                flow: {
                    flowId: "someNewFlow",
                    title: {
                        en: "someNewFlowEN",
                        de: "someNewFlowDE",
                        fr: "someNewFlowFR",
                        it: "someNewFlowIT"
                    },
                    isRootFlow: false,
                    chatFlow: []
                }
            }
            return mockData.flow;
        }
        catch (error) {
            throw new AvatarDataSourceError(`[addFlow] - ${error.message}`)
        }
    }

    getAvatarFlowMock(version: string, categoryId: string, flowId: string): AvatarFlowData {
        return AvatarFlowMock[0].categories[0].flows[0];
    }

    getAvatarFlowViewMock(version: string, categoryId: string, flowId: string): AvatarFlowViewData {
        return AvatarFlowViewPreview.flow
    }

    getAvatarCategoryViewMock(version: string, categoryId: string, flowId: string): AvatarCategoryViewData {
        return AvatarFlowViewPreview.category;
    }

    getFlowByIdMock(version: string, categoryId: string, flowId: string): AvatarPreviewData {
        const flowInfo = AvatarFlowMock[0].categories[0].flows[0];
        const flow = AvatarFlowViewPreview.flow;
        const category = AvatarFlowViewPreview.category;

        return { flow, flowInfo, category };
    }

    getAvatarCategoryByIdMock(version: string, categoryId: string): AvatarCategoryViewData {
        return AvatarFlowViewPreview.category
    }

    getAvatarsMock(): AvatarData[] {
        return AvatarMock
    }
}