import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCell, { AvatarCellProps } from "./AvatarCell";

export class UserOptionsError extends Error {
    constructor(message: string) {
        super(`[UserOptionsError] Error - ${message}`);
    }
}

export type UserOptionsProps =  AvatarCellProps & {goTo: string} 

export default class UserOptions extends AvatarCell {    
    protected _goTo: NotEmptyString

    constructor(props: UserOptionsProps, id: StringId) {
        super(props, id)
        this._goTo = NotEmptyString.create(props.goTo)
    }

    get goTo(): NotEmptyString { 
        return this._goTo;
    }

    public equals(object: UserOptions): boolean {
        return super.equals(object) && this._goTo.value === object._goTo.value
    }
}