import { AvatarCategoryViewViewModel } from "../../../presenter/avatar/viewModel/AvatarViewModels";

export const ADD_CATEGORY_CHANGE_INPUTS = "ADD_CATEGORY_CHANGE_INPUTS";
export const ADD_CATEGORY_INPUT_ERROR = "ADD_CATEGORY_INPUT_ERROR";
export const CATEGORY_INPUT_RESET = "CATEGORY_INPUT_RESET";
export const SET_CATEGORY_INPUTS = "SET_CATEGORY_INPUTS"

export type AddCategoryInputsModel = {
    field: string;
    value: string;
}

export type AddCategoryErrorModel = {
    field: string;
    value: boolean;
}

interface AddCategoryChangeInputs {
    type: typeof ADD_CATEGORY_CHANGE_INPUTS,
    payload: AddCategoryInputsModel
}

interface AddCategoryInputError {
    type: typeof ADD_CATEGORY_INPUT_ERROR,
    payload: AddCategoryErrorModel
}

interface SetCategoryInputs {
    type: typeof SET_CATEGORY_INPUTS,
    payload: {
        categoryView: AvatarCategoryViewViewModel
    }
}

interface CategoryInputReset {
    type: typeof CATEGORY_INPUT_RESET
}

export type AvatarCategoryActionTypes = 
AddCategoryChangeInputs | AddCategoryInputError | CategoryInputReset | SetCategoryInputs;