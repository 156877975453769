import AvatarCategoryView from "../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import { ADD_AVATAR_CATEGORY_SUCCESS } from "../../redux/avatar/AvatarActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import IBasicOutput from "../output/IBasicOutput";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarCategoryPresentation from "./presentation/AvatarCategoryPresentation";

export default class AddAvatarCategoryPresenter extends ReduxPresenter implements IBasicOutput<AvatarCategoryView> {
    constructor(dispatch: IReduxDispatch, private categoryPresentation: AvatarCategoryPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse(category: AvatarCategoryView): void {
        const categoryViewVM = this.categoryPresentation.presentCategoryView(category);
        const categoryVM = this.categoryPresentation.presentCategory(category);
        
        this.dispatch({
            type: ADD_AVATAR_CATEGORY_SUCCESS,
            payload: {
                category: categoryVM,
                categoryView: categoryViewVM
            }
        })
    }
}