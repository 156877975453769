import UtilityLogo from "../../../domain/modules/utility/valueObject/UtilityLogo";
import { UtilityViewModel } from "../../presenter/utility/viewmodel/UtilityViewModel";

export const GET_UTILITY_SUCCESS = "GET_UTILITY_SUCCESS";
export const GET_UTILITY_ERROR = "GET_UTILITY_ERROR";

export const EDIT_UTILITY_SET = "EDIT_UTILITY_SET";
export const EDIT_UTILITY_RESET = "EDIT_UTILITY_RESET";
export const EDIT_UTILITY_INPUT = "EDIT_UTILITY_INPUT";
export const EDIT_UTILITY_ERROR = "EDIT_UTILITY_ERROR";
export const EDIT_UTILITY_LOADING = "EDIT_UTILITY_LOADING";
export const EDIT_UTILITY_SUCCESS = "EDIT_UTILITY_SUCCESS";

export const UTILITY_LOGO_RESET_SELECTED = "UTILITY_LOGO_RESET_SELECTED";
export const UTILITY_LOGO_SET_SELECTED = "UTILITY_LOGO_SET_SELECTED";
export const UTILITY_LOGO_UPDATE = "UTILITY_LOGO_UPDATE";

export const UTILITY_GET_LOGOS_ERROR = "UTILITY_GET_LOGOS_ERROR";
export const UTILITY_GET_LOGOS_SUCCESS = "UTILITY_GET_LOGOS_SUCCESS";

export const UTILITY_LOGO_FILE_UPLOAD = "UTILITY_LOGO_FILE_UPLOAD";

export type EditUtilityInputsModel = {
    field: string;
    value: string;
}

interface GetUtilitySuccess {
    type: typeof GET_UTILITY_SUCCESS;
    payload: {
        utility: UtilityViewModel;
    };
}

interface GetUtilityError {
    type: typeof GET_UTILITY_ERROR;
    payload: {
        errorMessage: string;
    };
}

interface EditUtilityReset {
    type: typeof EDIT_UTILITY_RESET;
}

interface EditUtilityInputs {
    type: typeof EDIT_UTILITY_INPUT;
    payload: {
        input: EditUtilityInputsModel;
    };
}

interface EditUtilityFail {
    type: typeof EDIT_UTILITY_ERROR;
    payload: {
        errorMessage: string;
    };
}

interface UtilityGetLogosError {
    type: typeof UTILITY_GET_LOGOS_ERROR;
    payload: {
        errorMessage: string;
    };
}

interface EditUtilitySuccess {
    type: typeof EDIT_UTILITY_SUCCESS;
}

interface EditUtilityLoading {
    type: typeof EDIT_UTILITY_LOADING;
}

interface UtilityGetLogos {
    type: typeof UTILITY_GET_LOGOS_SUCCESS;
    payload: {
        logos: UtilityLogo[];
    }
}

interface UtilityLogoSetSelected {
    type: typeof UTILITY_LOGO_SET_SELECTED;
    payload: {
        selectedLogoType: string;
        selectedLogoUrl: string;
    }
}

interface UtilityLogoResetSelected {
    type: typeof UTILITY_LOGO_RESET_SELECTED;
}

interface UtilityLogoUpdate {
    type: typeof UTILITY_LOGO_UPDATE;
    payload: {
        url: string;
    }
}

interface UtilityLogoFileUpload {
    type: typeof UTILITY_LOGO_FILE_UPLOAD;
    payload: {
        file: File;
    }
}

export type UtilityActionTypes = GetUtilityError | GetUtilitySuccess |
    EditUtilityReset | EditUtilityInputs | UtilityLogoUpdate |
    EditUtilityFail | EditUtilityLoading | EditUtilitySuccess |
    UtilityGetLogos | UtilityGetLogos | UtilityGetLogosError | UtilityLogoSetSelected | UtilityLogoResetSelected |
    UtilityLogoFileUpload;