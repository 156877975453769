import AvatarCategoryView from "../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import { IGetAvatarCategoryByIdOutput } from "../../../domain/modules/avatar/useCase/GetAvatarCategoryByIdUseCase";
import { GET_AVATAR_CATEGORY_BY_ID_SUCCESS } from "../../redux/avatar/AvatarActionTypes";
import { SET_CATEGORY_INPUTS } from "../../redux/avatar/category/AvatarCategoryActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarCategoryPresentation from "./presentation/AvatarCategoryPresentation";

export default class GetAvatarCategoryByIdPresenter extends ReduxPresenter implements IGetAvatarCategoryByIdOutput {
    constructor(
        dispatch: IReduxDispatch,
        private categoryViewPresentation: AvatarCategoryPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse = (category: AvatarCategoryView): void => {
        const categoryVVM = this.categoryViewPresentation.presentCategoryView(category);

        this.dispatch({
            type: SET_CATEGORY_INPUTS,
            payload: {
                categoryView: categoryVVM,
            }
        })
        
        this.dispatch({
            type: GET_AVATAR_CATEGORY_BY_ID_SUCCESS,
            payload: {
                categoryView: categoryVVM,
            }
        })
    }
}