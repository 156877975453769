import { AvatarPreview } from "../../../domain/modules/avatar/gateway/IGetAvatarFlowByIdGateway";
import { IGetAvatarFlowByIdOutput } from "../../../domain/modules/avatar/useCase/GetAvatarFlowByIdUseCase";
import { GET_AVATAR_FLOW_BY_ID_SUCCESS } from "../../redux/avatarEdit/AvatarEditActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarCategoryPresentation from "./presentation/AvatarCategoryPresentation";
import AvatarFlowPresentation from "./presentation/AvatarFlowPresentation";

export default class GetAvatarFlowByIdPresenter extends ReduxPresenter implements IGetAvatarFlowByIdOutput {
    constructor(
        dispatch: IReduxDispatch,
        private flowPresentation: AvatarFlowPresentation,
        private categoryViewPresentation: AvatarCategoryPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse = (preview: AvatarPreview): void => {
        const { flow, flowInfo, category } = preview;
        const flowInfoVM = this.flowPresentation.presentFlow(flowInfo);
        const flowViewVM = this.flowPresentation.presentFlowView(flow);
        const categoryVM = this.categoryViewPresentation.presentCategoryView(category);

        this.dispatch({
            type: GET_AVATAR_FLOW_BY_ID_SUCCESS,
            payload: {
                flow: flowViewVM,
                flowInfo: flowInfoVM,
                category: categoryVM
            }
        })
    }
}