import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { GetUtilityListInteractor } from "../../../domain/modules/utility/useCase/GetUtilityListUseCase";
import GetUtilityListPresenter from "../../presenter/utility/GetUtilityListPresenter";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import UtilityPresentation from "../../presenter/utility/presentation/UtilityPresentation";
import { GET_UTILITIES_LOADING } from "./UtilityListActionTypes";

export const GetUtilities = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_UTILITIES_LOADING
    });

    new GetUtilityListInteractor(new GetUtilityListPresenter(new ReduxDispatch(dispatch), new UtilityPresentation()),
        dependencyContainer.dependency.gatewayFactory.getGetUtilityListGateway())
        .getUtilityList();
}