import { Container, makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { UtilityViewModel } from "../../presenter/utility/viewmodel/UtilityViewModel";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface UtilityTableProps {
  utilityList: UtilityViewModel[];
  handleEdit: (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData: UtilityViewModel | UtilityViewModel[]
  ) => void;
}

const useStyles = makeStyles(() => ({
  id: {
    cursor: "pointer"
  },
  main: {
    marginTop: "-1vh"
  }
}));

export default function UtilityTable(props: UtilityTableProps) {
  const classes = useStyles();
  const { utilityList, handleEdit } = props;

  return (
    <Container
    className={classes.main}
      maxWidth={false}
      disableGutters={true}
    >
      <MaterialTable
        columns={[
          {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by name",
            width: "30%",
            render: (rowData: UtilityViewModel) => <div className={classes.id} onClick={(e: any) => handleEdit(e, rowData)}>{rowData.name}</div>
          },
          {
            title: "Short name",
            field: "shortName",
            filterPlaceholder: "Filter by short name",
            width: "30%",
            render: (rowData: UtilityViewModel) => <div className={classes.id} onClick={(e: any) => handleEdit(e, rowData)}>{rowData.shortName}</div>
          },
          {
            title: "Utility ID",
            field: "id",
            filterPlaceholder: "Filter by utility ID",
            width: "30%",
            render: (rowData: UtilityViewModel) => <div className={classes.id} onClick={(e: any) => handleEdit(e, rowData)}>{rowData.id}</div>
          }
        ]}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit Utility",
            onClick: handleEdit
          },
          {
            icon: () => <DeleteOutlineIcon />,
            tooltip: "Delete Utility",
            disabled: true,
            onClick: () => {},
          }
        ]}
        data={utilityList}
        options={{
          filtering: true,
          search: false,
          showTitle: false,
          pageSize: 10,
          pageSizeOptions: [10, 15, 20, 25],
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1
        }}
      />
    </Container>
  );
}
