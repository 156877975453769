import { AvatarFlowInputData } from "../../../../../infrastructure/dataSource/avatarDataSource/IAvatarDataSource";
import NotEmptyString from "../../../../base/valueObject/NotEmptyString";
import MultilingualString from "../../valueObject/MultilingualString";

export class AddAvatarFlowInputProcessorError extends Error {
    constructor(message: string) {
        super(`[AddAvatarFlowInputProcessorError] Error - ${message}`);
    }
}

export type AvatarFlowInputs = {
    title: MultilingualString
}

export default class AddAvatarFlowInputProcessor {
    public static processData(data: AvatarFlowInputData): AvatarFlowInputs {
        try {
            const { en, de, fr, it } = data;
            return {
                title: MultilingualString.create(
                    NotEmptyString.create(en),
                    NotEmptyString.create(de),
                    NotEmptyString.create(fr),
                    NotEmptyString.create(it)
                )
            }
        } catch (error) {
            throw new AddAvatarFlowInputProcessorError(error.message);
        }
    }
}