import { AvatarCategoryInputData } from "../../../../../infrastructure/dataSource/avatarDataSource/IAvatarDataSource";
import NotEmptyString from "../../../../base/valueObject/NotEmptyString";
import MultilingualString from "../../valueObject/MultilingualString";

export class AddAvatarCategoryInputProcessorError extends Error {
    constructor(message: string) {
        super(`[AddAvatarCategoryInputProcessorError] Error - ${message}`);
    }
}

export type AvatarCategoryInputs = {
    text: MultilingualString
}

export default class AddAvatarCategoryInputProcessor {
    public static processData(data: AvatarCategoryInputData): AvatarCategoryInputs {
        try {
            const { en, de, fr, it } = data;
            return {
                text: MultilingualString.create(
                    NotEmptyString.create(en),
                    NotEmptyString.create(de),
                    NotEmptyString.create(fr),
                    NotEmptyString.create(it)
                )
            }
        } catch (error) {
            throw new AddAvatarCategoryInputProcessorError(error.message);
        }
    }
}