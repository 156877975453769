import { UserOptionsViewModel, AvatarTextViewModel, AvatarImageViewModel } from "../../presenter/avatar/viewModel/AvatarViewModels"
import { AvatarFlowEditActionTypes, SET_AVATAR_FLOW_EDIT, GET_AVATAR_FLOW_EDIT_FAIL, AVATAR_FLOW_EDIT_INPUTS, GET_AVATAR_FLOW_BY_ID_FAIL, GET_AVATAR_FLOW_BY_ID_SUCCESS } from "./AvatarEditActionTypes"

interface AvatarFlowState {
    id: string;
    isPublished: boolean;
    isDrafted: boolean;
    isRootFlow: boolean;
    triggerId: string;
    comment: string;
    title: {
        en: string;
        de: string;
        fr: string;
        it: string;
    };
    cells: Array<UserOptionsViewModel | AvatarTextViewModel | AvatarImageViewModel>;
    loading: boolean;
    error?: string;
}

const defaultState: AvatarFlowState = {
    id: "",
    isPublished: false,
    isDrafted: false,
    isRootFlow: false,
    triggerId: "",
    comment: "",
    title: {
        en: "",
        de: "",
        fr: "",
        it: ""
    },
    cells: [],
    loading: false
}

const avatarFlowReducer = (state: AvatarFlowState = defaultState, action: AvatarFlowEditActionTypes): AvatarFlowState => {
    switch (action.type) {
        case SET_AVATAR_FLOW_EDIT:
            let { flow, flowInfo } = action.payload;
            return {
                ...state,
                id: flow.id,
                isPublished: flowInfo.isPublished,
                isDrafted: flowInfo.isDrafted,
                isRootFlow: flow.isRootFlow,
                title: {
                    en: flow.title.en,
                    de: flow.title.de,
                    fr: flow.title.fr,
                    it: flow.title.it
                },
                cells: flow.cells,
                comment: flow.comment,
                triggerId: flow.triggerId,
                loading: false
            }
        case GET_AVATAR_FLOW_EDIT_FAIL:
            return { ...state, error: action.payload.errorMessage }
        case AVATAR_FLOW_EDIT_INPUTS:
            return { ...state, [action.payload.field]: action.payload.value }
        case GET_AVATAR_FLOW_BY_ID_SUCCESS:
            return {
                ...state,
                id: action.payload.flow.id,
                isPublished: action.payload.flowInfo.isPublished,
                isDrafted: action.payload.flowInfo.isDrafted,
                isRootFlow: action.payload.flow.isRootFlow,
                title: {
                    en: action.payload.flow.title.en,
                    de: action.payload.flow.title.de,
                    fr: action.payload.flow.title.fr,
                    it: action.payload.flow.title.it
                },
                cells: action.payload.flow.cells,
                comment: action.payload.flow.comment,
                triggerId: action.payload.flow.triggerId,
                loading: false
            }
        case GET_AVATAR_FLOW_BY_ID_FAIL:
            return { ...state, error: action.payload.errorMessage }
        default:
            return state
    }
}

export default avatarFlowReducer;