import StringId from "../../../domain/base/valueObject/uniqueEntityID/StringId";
import AvatarFlow from "../../../domain/modules/avatar/entity/AvatarFlow";
import AvatarCategoryView from "../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import AvatarFlowView from "../../../domain/modules/avatar/entity/view/AvatarFlowView";
import { AvatarPreview } from "../../../domain/modules/avatar/gateway/IGetAvatarFlowByIdGateway";
import { AvatarCategoryInputs } from "../../../domain/modules/avatar/useCase/processor/AddAvatarCategoryInputProcessor";
import { AvatarFlowInputs } from "../../../domain/modules/avatar/useCase/processor/AddAvatarFlowInputProcessor";
import AvatarList from "../../../domain/modules/avatar/valueObject/AvatarList";
import IAvatarDataSource from "../../dataSource/avatarDataSource/IAvatarDataSource";
import IAvatarMapperFactory from "./factory/IAvatarMapperFactory";
import IAvatarRepository from "./IAvatarRepository";


export class AvatarRepositoryError extends Error {
    constructor(message: string) {
        super(`[AvatarRepository] Error - ${message}`)
    }
}

export default class AvatarRepository implements IAvatarRepository {
    constructor(
        private _dataSource: IAvatarDataSource,
        private _mapperFactory: IAvatarMapperFactory
    ) { }

    public async getAvatars(): Promise<AvatarList> {
        try {
            // const avatars = await this._dataSource.getAvatars()
            const avatars = this._dataSource.getAvatarsMock();
            const avatarsMap = avatars.map(avatarData => this._mapperFactory.getAvatarMapper().map(avatarData));
            return AvatarList.create(avatarsMap);
        } catch (error) {
            throw new AvatarRepositoryError(`[getAvatars] - ${error.message}`)
        }
    }

    public async getAvatarFlowView(version: StringId, categoryId: StringId, flowId: StringId): Promise<AvatarFlowView> {
        try {
            return this._mapperFactory.getAvatarFlowViewMapper().map(
                // await this._dataSource.getAvatarFlowView(version.getId(), categoryId.getId(), flowId.getId())
                this._dataSource.getAvatarFlowViewMock(version.getId(), categoryId.getId(), flowId.getId())
            );
        } catch (e) {
            throw new AvatarRepositoryError(`[getAvatarFlowView] - ${e.message}`)
        }
    }

    public async getAvatarCategoryView(version: StringId, categoryId: StringId, flowId: StringId): Promise<AvatarCategoryView> {
        try {
            return this._mapperFactory.getAvatarCategoryViewMapper().map(
                // await this._dataSource.getAvatarCategoryView(version.getId(), categoryId.getId(), flowId.getId())
                this._dataSource.getAvatarCategoryViewMock(version.getId(), categoryId.getId(), flowId.getId())
            );
        } catch (e) {
            throw new AvatarRepositoryError(`[getAvatarCategoryView] - ${e.message}`)
        }
    }

    public async getAvatarFlow(version: StringId, categoryId: StringId, flowId: StringId): Promise<AvatarFlow> {
        try {
            return this._mapperFactory.getAvatarFlowMapper().map(
                // await this._dataSource.getAvatarFlow(version.getId(), categoryId.getId(), flowId.getId())
                await this._dataSource.getAvatarFlowMock(version.getId(), categoryId.getId(), flowId.getId())
            );
        } catch (e) {
            throw new AvatarRepositoryError(`[getAvatarFlow] - ${e.message}`)
        }
    }

    public async getFlowById(version: StringId, categoryId: StringId, flowId: StringId): Promise<AvatarPreview> {
        const result = await this._dataSource.getFlowByIdMock(version.getId(), categoryId.getId(), flowId.getId());

        const flow = this._mapperFactory.getAvatarFlowViewMapper().map(result.flow);
        const flowInfo = this._mapperFactory.getAvatarFlowMapper().map(result.flowInfo);
        const category = this._mapperFactory.getAvatarCategoryViewMapper().map(result.category);

        return { flowInfo, flow, category };
    }

    public async addCategory(version: StringId, category: AvatarCategoryInputs): Promise<AvatarCategoryView> {
        try {
            const result = await this._dataSource.addCategory(version.getId(), this._mapperFactory.getAvatarCategoryInputDataMapper().map(category));
            return this._mapperFactory.getAvatarCategoryViewMapper().map(result);
        } catch (e) {
            throw new AvatarRepositoryError(`[addCategory] - ${e.message}`)
        }
    }

    public async getAvatarCategoryById(version: StringId, categoryId: StringId): Promise<AvatarCategoryView> {
        try {
            const result = await this._dataSource.getAvatarCategoryByIdMock(version.getId(), categoryId.getId());
            // const result = await this._dataSource.getAvatarCategoryById(version.getId(), categoryId.getId());

            const categoryView = this._mapperFactory.getAvatarCategoryViewMapper().map(result);

            return categoryView;
        } catch (e) {
            throw new AvatarRepositoryError(`[getAvatarCategoryById] - ${e.message}`)
        }
    }

    public async deleteAvatarCategory(version: StringId, categoryId: StringId): Promise<StringId> {
        try {
            await this._dataSource.deleteAvatarCategory(version.getId(), categoryId.getId());
            // await this._dataSource.deleteAvatarCategory(version.getId(), categoryId.getId());
            return categoryId ;
        } catch (e) {
            throw new AvatarRepositoryError(`[deleteAvatarCategory] - ${e.message}`)
        }
    }

    public async editAvatarCategory(version: StringId, categoryId: StringId, category: AvatarCategoryView): Promise<AvatarCategoryView> {
        try {
            await this._dataSource.editAvatarCategory(version.getId(), categoryId.getId(), this._mapperFactory.getAvatarCategoryViewDataMapper().map(category))
            return category
        } catch (e) {
            throw new AvatarRepositoryError(`[editAvatarCategory] - ${e.message}`)
        }
    }

    public async addFlow(version: StringId, category: StringId, flow: AvatarFlowInputs): Promise<AvatarFlowView> {
        try {
            const result = await this._dataSource.addFlow(version.getId(), category.getId(), this._mapperFactory.getAvatarFlowInputDataMapper().map(flow));
            return this._mapperFactory.getAvatarFlowViewMapper().map(result);
        } catch (e) {
            throw new AvatarRepositoryError(`[addFlow] - ${e.message}`)
        }
    }
}