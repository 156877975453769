import AvatarFlow from "../../../domain/modules/avatar/entity/AvatarFlow";
import AvatarFlowView from "../../../domain/modules/avatar/entity/view/AvatarFlowView";
import { IGetAvatarFlowEditOutput } from "../../../domain/modules/avatar/useCase/GetAvatarFlowEditUseCase";
import { SET_AVATAR_FLOW_EDIT } from "../../redux/avatarEdit/AvatarEditActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarFlowPresentation from "./presentation/AvatarFlowPresentation";

export default class AvatarFlowEditPresenter extends ReduxPresenter implements IGetAvatarFlowEditOutput {
    constructor(dispatch: IReduxDispatch,
        private flowPresentation: AvatarFlowPresentation,
        private flowViewPresentation: AvatarFlowPresentation
    ) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse(flow: AvatarFlowView, flowInfo: AvatarFlow): void {
        const flowInfoVM = this.flowPresentation.presentFlow(flowInfo)
        const flowVM = this.flowViewPresentation.presentFlowView(flow);

        this.dispatch({
            type: SET_AVATAR_FLOW_EDIT,
            payload: {
                flowInfo: flowInfoVM,
                flow: flowVM
            }
        })
    }
}