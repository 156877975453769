import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import Entity from "../../../base/entity/Entity";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";

export class AvatarFlowError extends Error {
    constructor(message: string) {
        super(`[AvatarFlowError] Error - ${message}`);
    }
}

interface AvatarFlowProps {
    isPublished: boolean
    isDrafted: boolean
    name: NotEmptyString
}

export default class AvatarFlow extends Entity<AvatarFlowProps, string> {
    get id(): StringId {
        return StringId.create(this._id.getId());
    }
    
    get name(): NotEmptyString {
        return this.props.name;
    }

    get isPublished(): boolean {
        return this.props.isPublished
    }

    get isDrafted(): boolean {
        return this.props.isDrafted
    }

    public equals(object: AvatarFlow): boolean {
        return super.equals(object) &&
            this.props.name.equals(object.props.name) &&
            (this.props.isPublished === object.props.isPublished) &&
            (this.props.isDrafted === object.props.isDrafted)
    }
}