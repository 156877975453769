import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCategoryView from "../entity/view/AvatarCategoryView";
import AvatarFlowView from "../entity/view/AvatarFlowView";
import IGetAvatarFlowPreviewGateway from "../gateway/IGetAvatarFlowPreviewGateway";
import { GetAvatarsInteractorError } from "./GetAvatarsUseCase";

export class GetAvatarFlowPreviewInteractorError extends Error {
    constructor(message: string) {
        super(`[GetFlowPreviewUseCase] Error - ${message}`)
    }
}

export interface IGetAvatarFlowPreviewInput {
    getAvatarFlowPreview(version: string, categoryId: string, flowId: string): Promise<void>;
}

export interface IGetAvatarFlowPreviewOutput {
    displaySuccessResponse(flowView: AvatarFlowView, categoryView: AvatarCategoryView): void;
    displayErrorResponse(error: Error): void;
}

export class GetAvatarFlowPreviewInteractor implements IGetAvatarFlowPreviewInput {
    constructor(
        private _output: IGetAvatarFlowPreviewOutput,
        private _gateway: IGetAvatarFlowPreviewGateway
    ) { }

    public async getAvatarFlowPreview(version: string, categoryId: string, flowId: string): Promise<void> {
        try {
            await this.interact(StringId.create(version), StringId.create(categoryId), StringId.create(flowId));
        } catch (e) {
            this._output.displayErrorResponse(new GetAvatarsInteractorError(e.message))
        }
    }

    private async interact(version: StringId, categoryId: StringId, flowId: StringId) {
        const categoryView = await this._gateway.getAvatarCategoryView(version, categoryId, flowId);
        const flowView = await this._gateway.getAvatarFlowView(version, categoryId, flowId);

        this._output.displaySuccessResponse(flowView, categoryView);
    }
}