import AvatarCategory from "../entity/AvatarCategory";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class AvatarCategoryListError extends Error {
    constructor(message: string) {
        super(`[AvatarCategoryList] Error - ${message}`)
    }
}

interface AvatarCategoryListProps {
    categories: AvatarCategory[]
}

export default class AvatarCategoryList extends ValueObject<AvatarCategoryListProps> {
    get categories(): AvatarCategory[] {
        return this.props.categories
    }

    private constructor(props: AvatarCategoryListProps) {
        super(props)
    }

    public static create(categories: AvatarCategory[]): AvatarCategoryList {
        if (!categories)
            throw new AvatarCategoryListError('Avatar flow list must not be undefined')        
        return new AvatarCategoryList({ categories })
    }

    public equals(vo: ValueObject<AvatarCategoryListProps>): boolean {

        if (this.props.categories.length !== vo.props.categories.length)
            return false

        let result = this.props.categories.filter(c1 => vo.props.categories.some(c2 => c1.id.isEqual(c2.id)));
       
        return result.length === this.props.categories.length
    }
}