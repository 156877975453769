import { Button, makeStyles } from "@material-ui/core";

interface UserOptionsChatProps {
  text: string
}

const useStyles = makeStyles((theme) => ({
  main: {
    background: '#6ee3ad',
    borderRadius: 50,
    color: 'black',
    "&:hover": {
      backgroundColor: '#6ee3ad',
    },
    fontSize: 20,
    maxWidth: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: 15
    },
  }
}));

export default function UserOptionsChat(props: UserOptionsChatProps) {
  const classes = useStyles();
  const { text } = props

  return (
    <Button
      className={classes.main}
      type="button"
      size="large"
      fullWidth
      variant="contained"
      color="primary">
      {text}
    </Button>
  );
}
