import { Redirect, Route } from "react-router-dom";
import Home from "../../page/home";
import User from "../../page/user/userForm";
import Users from "../../page/user/usersTable";
import Utilities from "../../page/utility/utilityTable";
import AppBreadcrumbs from "../AppBreadcrumbs";
import renderWithNavigation from "../HOC/renderWithNavigation";
import Utility from "../../page/utility/utilityForm";
import Profiles from "../../page/profile/profileList";
import Mappings from "../../page/mapping/mappingList";
import StandardProfiles from "../../page/standardProfile";
import Avatar from "../../page/avatar";
import { Container } from "@material-ui/core";
import AvatarFlowEdit from "../../page/avatar/avatarEdit";
import Versioning from "../../page/versioning";

function AuthorizedRoutes() {
  return (
    <Container maxWidth={false} disableGutters={true}>
      <Route path="*" component={AppBreadcrumbs} />
      <Route path="/app/home" component={Home} />
      <Route exact path="/app/users" component={Users} />
      <Route path="/app/users/:id" component={User} />
      <Route exact path="/app/utilities" component={Utilities} />
      <Route path="/app/utilities/:utilityId" component={Utility} />
      {/* <Route path="/app/utilities/:shortName" component={Utility} /> */}
      <Route path="/app/standard-profiles" component={StandardProfiles} />
      <Route path="/app/standard-profiles/profiles" component={Profiles} />
      <Route path="/app/standard-profiles/mappings" component={Mappings} />
      <Route path="/app/versioning" component={Versioning} />
      <Route exact path="/app/avatar" component={Avatar} />
      <Route path="/app/avatar/:version/categories/:categoryId/:flowId" component={AvatarFlowEdit} />
      <Route exact path="/app/avatar/:version">
        <Redirect to="/app/avatar" />
      </Route>
      <Route exact path="/app/avatar/:version/categories">
        <Redirect to="/app/avatar" />
      </Route>
      <Route exact path="/app/avatar/:version/categories/:categoryId">
        <Redirect to="/app/avatar" />
      </Route>
    </Container>
  );
}
export default renderWithNavigation(AuthorizedRoutes);
