import AvatarCell from "./AvatarCell";

export class AvatarTextError extends Error {
    constructor(message: string) {
        super(`[AvatarTextError] Error - ${message}`);
    }
}

export default class AvatarText extends AvatarCell{    

}