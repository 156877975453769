import AvatarCategoryView from "../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import { IEditAvatarCategoryOutput } from "../../../domain/modules/avatar/useCase/EditAvatarCategoryUseCase";
import { EDIT_AVATAR_CATEGORY_SUCCESS } from "../../redux/avatar/AvatarActionTypes";
import { BASE_SNACK_OPEN } from "../../redux/base/BaseActionTypes";
import { IReduxDispatch } from "../IReduxDispatch";
import ReduxPresenter from "../ReduxPresenter";
import AvatarErrors from "./AvatarErrors";
import AvatarCategoryPresentation from "./presentation/AvatarCategoryPresentation";

export default class EditAvatarCategoryPresenter extends ReduxPresenter implements IEditAvatarCategoryOutput {
    constructor(dispatch: IReduxDispatch, private categoryPresentation: AvatarCategoryPresentation) {
        super(dispatch, new AvatarErrors().processor);
    }

    displaySuccessResponse(category: AvatarCategoryView): void {
        const categoryViewVM = this.categoryPresentation.presentCategoryView(category);
        
        this.dispatch({
            type: EDIT_AVATAR_CATEGORY_SUCCESS,
            payload: {
                categoryView: categoryViewVM
            }
        })

        this.dispatch({
            type: BASE_SNACK_OPEN,
            payload: {
                snackText: "Category updated successfully!"
            }
        })
    }
}