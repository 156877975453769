import React from "react";
import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "../MenuItem";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { SignOutAction } from "../../redux/signOut/SignOutActions";
import { RootStore } from "../../redux/Store";
import ErrorMessage from "../ErrorMessage";
import { Icon } from "@material-ui/core";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      color: theme.palette.secondary.main,
      marginRight: 36,
    },
    signOutButton: {
      color: theme.palette.secondary.main,
      marginLeft: "auto",
      marginRight: -12,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    error: {
      width: theme.spacing(25),
      margin: "auto",
    },
    imageIcon: {
      height: '100%'
    },
    iconRoot: {
      textAlign: 'center',
      marginRight: '10px'
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      cursor: 'default'
    },
    version: {
      color: 'black',
      textDecoration: 'none',
      marginLeft: '1vw',
      fontSize: '18px'
    },
  })
);

const MENU_ITEMS = [
  // { title: "Home", url: "/app/home" },
  { title: "Users", url: "/app/users" },
  { title: "Utilities", url: "/app/utilities" },
  // { title: "Tips", url: "/app/tips" },
  { title: "Avatar", url: "/app/avatar" },
  { title: "Standard profiles", url: "/app/standard-profiles" },
  // { title: "Requests", url: "/app/requests" },
];

function isMenuItemSelected(pathname: string, menuItemUrl: string): boolean {
  return menuItemUrl === "/home"
    ? pathname === menuItemUrl
    : pathname.indexOf(menuItemUrl) === 0;
}

function getTitleForPage(pathname: string): string {
  const parts = pathname.split("/").filter((i) => i.length > 0);
  if (parts.length > 1)
    return `${parts[1][0].toUpperCase()}${parts[1].substr(1, parts[1].length)}`;
  return "AliunidHOME admin portal";
}

export default function renderWithNavigation(
  WrappedComponent: React.ComponentType<any>
) {
  return () => {
    const classes = useStyles();
    const theme = useTheme();
    const { pathname } = useLocation();
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("AliunidHOME admin portal");
    const dispatch = useDispatch();

    const signOutError = useSelector(
      (state: RootStore) => state.signOutReducer.error
    );

    React.useEffect(() => {
      setTitle(getTitleForPage(pathname));
    }, [pathname, setTitle]);

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    const onSignOut = () => {
      dispatch(SignOutAction());
    };

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Icon classes={{ root: classes.iconRoot }}>
              <img className={classes.imageIcon} src="/aliunid-flower.svg" alt="aliunid-logo" />
            </Icon>
            <Typography color="secondary" variant="h6" noWrap>
              {title}
            </Typography>
            <IconButton
              aria-label="signOut"
              onClick={onSignOut}
              edge="end"
              className={classes.signOutButton}
            >
              <SignOutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Link to="/app/versioning" className={classes.version}>v1.0.0</Link>
            <IconButton color='default' onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {MENU_ITEMS.map(({ title, url }, index) => (
              <MenuItem
                title={title}
                url={url}
                key={index}
                selected={isMenuItemSelected(pathname, url)}
              />
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.error}>
            {signOutError && <ErrorMessage message={signOutError} />}
          </div>
          <WrappedComponent />
        </main>
      </div>
    );
  };
}