import { UserData } from "../../infrastructure/dataSource/userDataSource/IUserDataSource"

export type TestUserDataInput = {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    language?: string;
    zipCode?: string;
    hasBlinker?: boolean;
    utilityId?: string;

    sharedData?: boolean,
    termsOfConditions?: boolean,

    notificationUtility?: boolean,
    notificationTips?: boolean,
    notificationProgress?: boolean,

    privacyCrashlytics?: boolean,
    privacyPerformance?: boolean,
    privacyPromotion?: boolean,
}

export default class TestUserDataFactory {
    public static createUserData(data: TestUserDataInput): UserData {
        return {
            id: this.merge('id-1', data.id),
            firstName: this.merge('John', data.firstName),
            lastName: this.merge('Stones', data.lastName),
            email: this.merge('johnstones@gmail.com', data.email),
            language: this.merge('en', data.language),
            zipCode: this.merge('18000', data.zipCode),
            hasBlinker: this.merge(true, data.hasBlinker),
            utilityId: this.merge("utility", data.utilityId),
            sharedData:  this.merge(true, data.sharedData),
            termsOfConditions:  this.merge(true, data.termsOfConditions),
            notificationUtility:  this.merge(true, data.notificationUtility),
            notificationTips:  this.merge(true, data.notificationTips),
            notificationProgress:  this.merge(true, data.notificationProgress),
            privacyCrashlytics:  this.merge(true, data.privacyCrashlytics),
            privacyPerformance:  this.merge(true, data.privacyPerformance),
            privacyPromotion:  this.merge(true, data.privacyPromotion)
        }
    }

    private static merge<T>(defaultValue: T, value?: T): T {
        return value === undefined ? defaultValue : value
    }
}