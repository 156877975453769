import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCategoryView, { AvatarCategoryViewUpdatableData } from "../entity/view/AvatarCategoryView";
import { IEditAvatarCategoryGateway } from "../gateway/IEditAvatarCategoryGateway";

export class EditAvatarCategoryError extends Error {
    constructor(message: string) {
        super(`[editAvatarCategory] Interactor Error - ${message}`);
    }
}

export interface IEditAvatarCategoryInput {
    editAvatarCategory(version: string, categoryId: string, category: AvatarCategoryViewUpdatableData ): Promise<void>;
}

export interface IEditAvatarCategoryOutput {
    displaySuccessResponse(category: AvatarCategoryView): void;
    displayErrorResponse(error: Error): void;
}

export class EditAvatarCategoryInteractor implements IEditAvatarCategoryInput {
    constructor(
        private _output: IEditAvatarCategoryOutput,
        private _gateway: IEditAvatarCategoryGateway
    ) { }

    public async editAvatarCategory(version: string, categoryId: string, categoryUpdate: AvatarCategoryViewUpdatableData): Promise<void> {
        try {
            await this.interact(StringId.create(version), StringId.create(categoryId), categoryUpdate);
        } catch (e) {
            this._output.displayErrorResponse(new EditAvatarCategoryError(e.message))
        }
    }

    private async interact(version: StringId, categoryId: StringId, categoryUpdate: AvatarCategoryViewUpdatableData) {
        const category = await this._gateway.getAvatarCategoryById(version, categoryId)
        const categoryEntity = category.update(categoryUpdate);
        const categoryView = await this._gateway.editAvatarCategory(version, categoryId, categoryEntity)
        this._output.displaySuccessResponse(categoryView);
    }
}