import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import Avatar from "../../../../domain/modules/avatar/entity/Avatar";
import AvatarFlow from "../../../../domain/modules/avatar/entity/AvatarFlow";
import AvatarCategory from "../../../../domain/modules/avatar/entity/AvatarCategory";
import MultilingualString from "../../../../domain/modules/avatar/valueObject/MultilingualString";
import { AvatarCategoryData, AvatarCategoryViewData, AvatarData, AvatarFlowData } from "../../../dataSource/avatarDataSource/IAvatarDataSource";
import IMapper from "../../../IMapper";
import AvatarCategoryList from "../../../../domain/modules/avatar/valueObject/AvatarCategoryList";
import AvatarFlowList from "../../../../domain/modules/avatar/valueObject/AvatarFlowList";
import AvatarCategoryView from "../../../../domain/modules/avatar/entity/view/AvatarCategoryView";

export default class AvatarMapper implements IMapper<AvatarData, Avatar> {
    map(input: AvatarData): Avatar {
        return new Avatar({
            categoryList: AvatarCategoryList.create(this.mapCategories(input.categories))
        }, StringId.create(input.id))
    }

    mapCategories(input: AvatarCategoryData[]): AvatarCategory[] {
        const avatarCategories = input.map(category => {
            return new AvatarCategory({
                name: NotEmptyString.create(category.name),
                flowList: AvatarFlowList.create(this.mapFlows(category.flows))
            }, StringId.create(category.categoryId))
        })
        return avatarCategories
    }

    mapFlows(input: AvatarFlowData[]): AvatarFlow[] {
        const avatarFlow = input.map(flow => {
            return new AvatarFlow({
                isPublished: flow.isPublished,
                isDrafted: flow.isDrafted,
                name: NotEmptyString.create(flow.name)
            }, StringId.create(flow.flowId))
        })
        return avatarFlow
    }

    mapCategoryView(input: AvatarCategoryViewData): AvatarCategoryView {
        return new AvatarCategoryView({
            title: MultilingualString.create(
                NotEmptyString.create(input.text.en),
                NotEmptyString.create(input.text.de),
                NotEmptyString.create(input.text.fr),
                NotEmptyString.create(input.text.it)
            )
        }, StringId.create(input.categoryId))
    }
}