import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import { AvatarPreview, IGetAvatarFlowByIdGateway } from "../gateway/IGetAvatarFlowByIdGateway";

export class GetAvatarFlowByIdError extends Error {
    constructor(message: string) {
        super(`[GetAvatarFlowById] Interactor Error - ${message}`);
    }
}

export interface IGetAvatarFlowByIdInput {
    getAvatarFlowById(version: string, categoryId: string, flowId: string): Promise<void>;
}

export interface IGetAvatarFlowByIdOutput {
    displaySuccessResponse(preview: AvatarPreview): void;
    displayErrorResponse(error: Error): void;
}

export class GetAvatarFlowByIdInteractor implements IGetAvatarFlowByIdInput {
    constructor(
        private _output: IGetAvatarFlowByIdOutput,
        private _gateway: IGetAvatarFlowByIdGateway
    ) { }

    public async getAvatarFlowById(version: string, categoryId: string, flowId: string): Promise<void> {
        try {
            await this.interact(StringId.create(version), StringId.create(categoryId), StringId.create(flowId));
        } catch (e) {
            this._output.displayErrorResponse(new GetAvatarFlowByIdError(e.message))
        }
    }

    private async interact(version: StringId, categoryId: StringId, flowId: StringId) {
        this._output.displaySuccessResponse(await this._gateway.getFlowById(version, categoryId, flowId));
    }
}