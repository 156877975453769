import { UtilityViewModel } from "../../presenter/utility/viewmodel/UtilityViewModel";
import { UtilityListActionTypes, GET_UTILITY_LIST_FAIL, GET_UTILITIES_LOADING, GET_UTILITY_LIST_SUCCESS } from "./UtilityListActionTypes";

interface UtilityListState {
    utilityList: UtilityViewModel[];
    loading: boolean;
    error?: string;
}

const defaultState: UtilityListState = {
    utilityList: [],
    loading: false
}

const utilityListReducer = (state: UtilityListState = defaultState, action: UtilityListActionTypes): UtilityListState => {
    switch (action.type) {
        case GET_UTILITIES_LOADING:
            return { ...state, loading: true }
        case GET_UTILITY_LIST_FAIL:
            return { ...state, loading: false, error: action.payload.errorMessage }
        case GET_UTILITY_LIST_SUCCESS:
            return { ...state, loading: false, utilityList: action.payload.utilityList }
        default:
            return state
    }
}

export default utilityListReducer;