import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  People,
  Mail,
  LocationCity,
  Textsms,
  SupervisedUserCircle,
  HelpOutline,
  // QuestionAnswer,
  ContactSupport
} from "@material-ui/icons";
import { useHistory } from "react-router";

interface MenuItemProps {
  title: string;
  url: string;
  selected: boolean;
}

function findIcon(name: string) {
  let result;
  switch (name) {
    case "Users":
      result = <People />;
      break;
    case "Utilities":
      result = <LocationCity />;
      break;
    case "Tips":
      result = <Textsms />;
      break;
    case "Avatar":
      result = <ContactSupport />;
      break;
    case "Standard profiles":
      result = <SupervisedUserCircle />;
      break;
    case "Requests":
      result = <HelpOutline />;
      break;
    default:
      result = <Mail />;
  }
  return result;
}

export default function MenuItem(props: MenuItemProps) {
  const { title, url, selected } = props;

  const history = useHistory();

  function handleClick(event: any) {
    history.push(url);
  }

  return (
    <ListItem button key={title} onClick={handleClick} selected={selected}>
      <ListItemIcon>{findIcon(title)}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
}
