import { AppBar, Card, Grid, IconButton, makeStyles, Tab, Tabs, Theme, Container } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useState } from "react";

interface AppModalProps {
    tabLabels: string[];
    tabs: JSX.Element[];
    handleCloseEditLogo: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        height: "80%",
        width: "80%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        margin: theme.spacing(0),
        padding: theme.spacing(0)
    },
    navigationContainer: {
        width: "100%",
        height: "auto",
        padding: theme.spacing(0, 0, 1)
    },
    contentContainer: {
        width: "100%",
        flexGrow: 1
    },
    tab: {
        color: theme.palette.primary.main
    },
    navigation: {
        backgroundColor: theme.palette.secondary.main,
        width: "100%"
    },
    indicator: {
        background: theme.palette.primary.main
    },
    closeButton: {
        margin: theme.spacing(0, 1, 0, 0),
        float: "right",
        color: theme.palette.error.main
    }
}));

export default function AppModal(props: AppModalProps) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Card raised={false} className={classes.root}>
            <Container maxWidth={false} disableGutters={true} className={classes.navigationContainer}>
                <AppBar position="relative" className={classes.navigation}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        alignContent="center"
                        spacing={1}
                    >
                        <Grid item xs={2} md={1} />
                        <Grid item xs={8} md={10}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                classes={{
                                    indicator: classes.indicator
                                }}
                                className={classes.tab}
                                centered
                            >
                                {props.tabLabels.map((el, i) => <Tab label={el} key={i} />)}
                            </Tabs>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleCloseEditLogo}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </AppBar>
            </Container>
            <Container maxWidth={false} disableGutters={true} className={classes.contentContainer}>
                {props.tabs[value]}
            </Container>
        </Card>
    );
}