import { AvatarFlowInputData } from "../../../../infrastructure/dataSource/avatarDataSource/IAvatarDataSource";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarFlowView from "../entity/view/AvatarFlowView";
import { IAddAvatarFlowGateway } from "../gateway/IAddAvatarFlowGateway";
import AddAvatarFlowInputProcessor, { AddAvatarFlowInputProcessorError, AvatarFlowInputs } from "./processor/AddAvatarFlowInputProcessor";

export class AddAvatarFlowInteractorError extends Error {
    constructor(message: string) {
        super(`[AddFlow] Error - ${message}`)
    }
}

export interface IAddAvatarFlowInput {
    addFlow(version: string, category: string, flow: AvatarFlowInputData): Promise<void>;
}

export interface IAddAvatarFlowOutput {
    displaySuccessResponse(flow: AvatarFlowView, categoryId: StringId): void;
    displayErrorResponse(error: Error): void;
}

export class AddAvatarFlowInteractor implements IAddAvatarFlowInput {
    constructor(
        private _output: IAddAvatarFlowOutput,
        private _gateway: IAddAvatarFlowGateway
    ) { }

    public async addFlow(version: string, category: string, flow: AvatarFlowInputData) {
        try {
            await this.interact(StringId.create(version), StringId.create(category), AddAvatarFlowInputProcessor.processData(flow));
        } catch (e) {
            this._output.displayErrorResponse(new AddAvatarFlowInputProcessorError(e.message))
        }
    }
    
    private async interact(version: StringId, category: StringId, flow: AvatarFlowInputs) {
        this._output.displaySuccessResponse(await this._gateway.addFlow(version, category, flow), category);
    }
}