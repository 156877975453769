import { Dispatch } from "redux"
import { dependencyContainer } from "../../../.."
import { AddAvatarCategoryInteractor } from "../../../../domain/modules/avatar/useCase/AddAvatarCategoryUseCase"
import AddAvatarCategoryPresenter from "../../../presenter/avatar/AddAvatarCategoryPresenter"
import AvatarCategoryPresentation from "../../../presenter/avatar/presentation/AvatarCategoryPresentation"
import { AvatarCategoryViewViewModel } from "../../../presenter/avatar/viewModel/AvatarViewModels"
import ReduxDispatch from "../../../presenter/ReduxDispatch"
import { RootStore } from "../../Store"
import { AVATAR_LOADING } from "../AvatarActionTypes"
import { AddCategoryErrorModel, AddCategoryInputsModel, ADD_CATEGORY_INPUT_ERROR, ADD_CATEGORY_CHANGE_INPUTS, CATEGORY_INPUT_RESET, SET_CATEGORY_INPUTS } from "./AvatarCategoryActionTypes"

export const AddCategoryChangeInputs = (payload: AddCategoryInputsModel) => (dispatch: Dispatch) => {
    dispatch({
        type: ADD_CATEGORY_CHANGE_INPUTS,
        payload
    })
}

export const AddCategoryInputError = (payload: AddCategoryErrorModel) => (dispatch: Dispatch) => {
    dispatch({
        type: ADD_CATEGORY_INPUT_ERROR,
        payload
    })
}

export const AddAvatarCategory = (version: string) => (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({ type: AVATAR_LOADING })

    const { en, de, fr, it } = getState().avatarCategoryReducer;

    new AddAvatarCategoryInteractor(new AddAvatarCategoryPresenter(new ReduxDispatch(dispatch), new AvatarCategoryPresentation()),
        dependencyContainer.dependency.gatewayFactory.getAddAvatarCategoryGateway())
        .addCategory(version, { en, de, fr, it })
}

export const CategoryInputReset = () => (dispatch: Dispatch) => {
    dispatch({ type: CATEGORY_INPUT_RESET })
}

export const SetCategoryInputs = (category: AvatarCategoryViewViewModel) => (dispatch: Dispatch) => {
    dispatch({
        type: SET_CATEGORY_INPUTS,
        payload: {
            categoryView: category,
        }
    })
    
}
