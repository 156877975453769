import Entity from "../../../base/entity/Entity";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCategoryList from "../valueObject/AvatarCategoryList";

export class AvatarError extends Error {
    constructor(message: string) {
        super(`[AvatarError] Error - ${message}`);
    }
}

interface AvatarProps {
    categoryList: AvatarCategoryList;
}

export default class Avatar extends Entity<AvatarProps, string> {
    get id(): StringId {
        return StringId.create(this._id.getId());
    }

    get categoryList(): AvatarCategoryList {
        return this.props.categoryList;
    }

    public equals(object: Avatar): boolean {
        return super.equals(object) &&
            (this.props.categoryList.categories.length === object.props.categoryList.categories.length) &&
            this.props.categoryList.categories.every(category => {
                return object.props.categoryList.categories.some(secondCategory => {
                    return category.equals(secondCategory)
                })
            })
    }
}