import AvatarCategoryView from "../../../../domain/modules/avatar/entity/view/AvatarCategoryView";
import { AvatarCategoryViewData } from "../../../dataSource/avatarDataSource/IAvatarDataSource"
import IMapper from "../../../IMapper";

export default class AvatarCategoryViewMapper implements IMapper<AvatarCategoryView, AvatarCategoryViewData> {
    map(input: AvatarCategoryView): AvatarCategoryViewData {
        const { id, title } = input
        return {
            categoryId: id.getId(),
            text: {
                en: title.en.value,
                de: title.de.value,
                fr: title.fr.value,
                it: title.it.value
            }
        }
    }
}
