import IGetMappingsGateway from "../domain/modules/mapping/gateway/IGetMappingsGateway";
import MappingDataSource from "../infrastructure/dataSource/mappingDataSource/MappingDataSource";
import StandardProfileDataSource from "../infrastructure/dataSource/standardProfileDataSource/StandardProfileDataSource";
import TokenDataSource from "../infrastructure/dataSource/tokenDataSource/TokenDataSource";
import UserDataSource from "../infrastructure/dataSource/userDataSource/UserDataSource";
import UtilityDataSource from "../infrastructure/dataSource/utilityDataSource/UtilityDataSource";
import LocalStorage from "../infrastructure/LocalStorage";
import NetworkController from "../infrastructure/network/NetworkController";
import AvatarRepository from "../infrastructure/repository/avatarRepository/AvatarRepository";
import AvatarRepositoryMapperFactory from "../infrastructure/repository/avatarRepository/factory/AvatarMapperFactory";
import IAvatarRepository from "../infrastructure/repository/avatarRepository/IAvatarRepository";
import MappingMapperFactory from "../infrastructure/repository/mappingRepository/factory/MappingMapperFactory";
import MappingGateway from "../infrastructure/repository/mappingRepository/gateway/MappingGateway";
import GetMappingsGateway from "../infrastructure/repository/mappingRepository/gateway/GetMappingsGateway";
import IMappingRepository from "../infrastructure/repository/mappingRepository/IMappingRepository";
import MappingRepository from "../infrastructure/repository/mappingRepository/MappingRepository";
import StandardProfileRepositoryMapperFactory from "../infrastructure/repository/standardProfileRepository/factory/StandardProfileMapperFactory";
import IStandardProfileRepository from "../infrastructure/repository/standardProfileRepository/IStandardProfileRepository";
import StandardProfileRepository from "../infrastructure/repository/standardProfileRepository/StandardProfileRepository";
import TokenRepositoryMapperFactory from "../infrastructure/repository/tokenRepository/factory/TokenRepositoryMapperFactory";
import { ITokenRepository } from "../infrastructure/repository/tokenRepository/ITokenRepository";
import { TokenRepository } from "../infrastructure/repository/tokenRepository/TokenRepository";
import UserRepositoryMapperFactory from "../infrastructure/repository/userRepository/factory/UserRepositoryMapperFactory";
import IUserRepository from "../infrastructure/repository/userRepository/IUserRepository";
import UserRepository from "../infrastructure/repository/userRepository/UserRepository";
import UtilityRepositoryMapperFactory from "../infrastructure/repository/utilityRepository/factory/UtilityRepositoryMapperFactory";
import { IUtilityRepository } from "../infrastructure/repository/utilityRepository/IUtilityRepository";
import { UtilityRepository } from "../infrastructure/repository/utilityRepository/UtilityRepository";
import IMappingGateway from "../domain/modules/mapping/gateway/IMappingGateway";
import AvatarDataSource from "../infrastructure/dataSource/avatarDataSource/AvatarDataSource";

export default class RepositoryFactory {
    constructor(private nwc: NetworkController, private storage: LocalStorage) { }

    getTokenRepository(): ITokenRepository {
        return new TokenRepository(
            new TokenDataSource(this.nwc, this.storage),
            new TokenRepositoryMapperFactory()
        );
    }


    getUserRepository(): IUserRepository {
        return new UserRepository(
            new UserDataSource(this.nwc),
            new UserRepositoryMapperFactory()
        )
    }

    getUtilityRepository(): IUtilityRepository {
        return new UtilityRepository(
            new UtilityDataSource(this.nwc),
            new UtilityRepositoryMapperFactory()
        );
    }

    getStandardProfileRepository(): IStandardProfileRepository {
        return new StandardProfileRepository(
            new StandardProfileDataSource(this.nwc),
            new StandardProfileRepositoryMapperFactory()
        )
    }

    getGetMappingsGateway(): IGetMappingsGateway {
        return new GetMappingsGateway(
            new StandardProfileRepository(
                new StandardProfileDataSource(this.nwc),
                new StandardProfileRepositoryMapperFactory()
            ),
            new MappingRepository(
                new MappingDataSource(this.nwc),
                new MappingMapperFactory()
            )
        )
    }

    getAvatarRepository(): IAvatarRepository {
        return new AvatarRepository(
            new AvatarDataSource(this.nwc),
            new AvatarRepositoryMapperFactory()
        )
    }
    
    getMappingRepository(): IMappingRepository {
        return new MappingRepository(
            new MappingDataSource(this.nwc),
            new MappingMapperFactory()
        );
    }

    getMappingGateway(): IMappingGateway {
        return new MappingGateway(
            new MappingRepository(
                new MappingDataSource(this.nwc),
                new MappingMapperFactory()
            ),
            new StandardProfileRepository(
                new StandardProfileDataSource(this.nwc),
                new StandardProfileRepositoryMapperFactory()
            )
        )
    }
}