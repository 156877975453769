import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import { AvatarCategoryInputData } from "../../../../infrastructure/dataSource/avatarDataSource/IAvatarDataSource";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarCategoryView from "../entity/view/AvatarCategoryView";
import { IAddAvatarCategoryGateway } from "../gateway/IAddAvatarCategoryGateway";
import AddAvatarCategoryInputProcessor, { AvatarCategoryInputs } from "./processor/AddAvatarCategoryInputProcessor";

export class AddAvatarCategoryInteractorError extends Error {
    constructor(message: string) {
        super(`[AddCategory] Error - ${message}`)
    }
}

export interface IAddAvatarCategoryInput {
    addCategory(version: string, category: AvatarCategoryInputData): Promise<void>;
}

export class AddAvatarCategoryInteractor implements IAddAvatarCategoryInput {
    constructor(
        private _output: IBasicOutput<AvatarCategoryView>,
        private _gateway: IAddAvatarCategoryGateway
    ) { }

    public async addCategory(version: string, category: AvatarCategoryInputData) {
        try {
            await this.interact(StringId.create(version), AddAvatarCategoryInputProcessor.processData(category));
        } catch (err) {
            this._output.displayErrorResponse(new AddAvatarCategoryInteractorError(err.message))
        }
    }

    private async interact(version: StringId, category: AvatarCategoryInputs) {
        this._output.displaySuccessResponse(await this._gateway.addCategory(version, category));
    }
}