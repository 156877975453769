import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import MultilingualString from "../valueObject/MultilingualString";

const isAvatarCell = (v: any): v is AvatarCell => {
    return v instanceof AvatarCell;
};

export interface AvatarCellProps {
    text: MultilingualString;
    typingAnimationLength: number;
}

export default abstract class AvatarCell {
    protected readonly _id: StringId;
    protected props: AvatarCellProps;

    constructor(props: AvatarCellProps, id: StringId) {
        this._id = id;
        this.props = props;
    }

    get id(): StringId {
        return StringId.create(this._id.getId());
    }

    get text(): MultilingualString {
        return this.props.text;
    }

    get typingAnimationLength(): number {
        return this.props.typingAnimationLength;
    }

    public equals(object: AvatarCell): boolean {

        if (object === null || object === undefined)
            return false;

        if (!isAvatarCell(object))
            return false;

        if (this === object)
            return true;

        return this._id.isEqual(object._id) &&
            this.props.text.equals(object.props.text) &&
            this.props.typingAnimationLength === object.props.typingAnimationLength
    }
}