import React from "react";
import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import mdFilePath from '../../../static/changelog.md';

export default function Versioning() {
  const [markdown, setMarkdown] = useState("");

  const fetchMd = async () => {    
    const mdFile = await fetch(mdFilePath);
    return mdFile.text();
  };

  useEffect(() => {
    fetchMd().then((md) => {
      setMarkdown(md);
    });
  }, []);

  return <Markdown>{markdown}</Markdown>;
}