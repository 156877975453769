import {
  Card,
  Container,
  Divider,
  Fab,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AvatarFlowPreview from "../../components/avatar/AvatarFlowPreview";
import AvatarAccordion from "../../components/AvatarAccordion";
import CustomDialog from "../../components/CustomDialog";
import CustomSelect from "../../components/CustomSelect";
import ErrorMessage from "../../components/ErrorMessage";
import Loader from "../../components/Loader";
import Snackbar from "../../components/Snackbar";
import { AvatarFlowLanguage } from "../../presenter/avatar/viewModel/AvatarViewModels";
import {
  ChangeAvatarLanguageAction,
  ChangeAvatarThemeAction,
  ChangeAvatarVersionAction,
  GetAvatarsAction,
  ResetAvatarFlowPreview,
  SetAvatarFlowPreview,
  GetAvatarCategoryById,
  EditAvatarCategory,
  DeleteAvatarCategory
} from "../../redux/avatar/AvatarActions";
import {
  AddAvatarCategory,
  AddCategoryInputError,
  AddCategoryChangeInputs,
  CategoryInputReset,
} from "../../redux/avatar/category/AvatarCategoryActions";
import { AddAvatarFlow, AddFlowChangeInputs, AddFlowInputsError, AddFlowReset } from "../../redux/avatar/flow/AvatarFlowActions";
import { SetAvatarFlowEdit } from "../../redux/avatarEdit/AvatarEditActions";
import { SnackClose } from "../../redux/base/BaseActions";
import { RootStore } from "../../redux/Store";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(-1.5),
  },
  content: {
    padding: theme.spacing(4, 4, 4, 4),
    marginTop: "-1vh",
  },
  versionSelect: {
    width: "12vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  loader: {
    top: "20%",
    left: "10%",
    position: "absolute",
    zIndex: 15,
    height: "80%",
    width: "80%",
  },
  verticalDivider: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  horizontalDivider: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  noFlow: {
    marginTop: "10vh",
    textAlign: "center",
  },
  fab: {
    float: "right",
    color: "#ffffff",
    marginTop: "-52px",
    marginRight: "-10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      marginRight: "0px",
    },
  },
  selectTheme: {
    marginRight: "1vw",
    marginLeft: "0.70vw",
  },
  textField: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2CDEA8",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2CDEA8",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#2CDEA8",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#2CDEA8",
    },
    marginBottom: "2px",
  },
}));

interface CategoryDialogProps {
  open: boolean;
  edit: boolean;
}

export default function Avatar() {
  const {
    avatarList,
    selectedAvatarVersion,
    selectedFlow,
    selectedCategory,
    dark,
    error,
    loading,
    language,
    previewLoading,
  } = useSelector((state: RootStore) => state.avatarReducer);

  const { snackOpen, snackText } = useSelector(
    (state: RootStore) => state.baseReducer
  );
  const categoryState = useSelector((state: RootStore) => state.avatarCategoryReducer);
  const flowState = useSelector((state: RootStore) => state.avatarFlowReducer);

  const [openNewFlow, setOpenNewFlow] = useState(false);
  const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
  const [categoryDialog, setCategoryDialog] = useState<CategoryDialogProps>({
    open: false,
    edit: false
  });
  const [categoryId, setCategoryId] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAvatarsAction());
  }, [dispatch]);

  const getVersionOptions = () => {
    return avatarList.map((avatar, index) => ({
      name: avatar.id,
      value: avatar.id,
    }));
  };

  const onFlowClick = (categoryId: string, flowId: string) => {
    if (selectedAvatarVersion) {
      dispatch(ResetAvatarFlowPreview());
      dispatch(
        SetAvatarFlowPreview(selectedAvatarVersion.id, categoryId, flowId)
      );
    }
  };

  const handleEditFlow = () => {
    if (selectedAvatarVersion && selectedCategory && selectedFlow)
      dispatch(
        SetAvatarFlowEdit(
          selectedAvatarVersion.id,
          selectedCategory.id,
          selectedFlow.id
        )
      );
  };

  const onThemeChange = () => {
    dispatch(ChangeAvatarThemeAction());
  };

  const onLanguageChange = (value?: string) => {
    if (value)
      dispatch(ChangeAvatarLanguageAction(value as AvatarFlowLanguage));
  };

  const onVersionChange = (value?: string) => {
    if (value) dispatch(ChangeAvatarVersionAction(value));
  };

  const editCategoryHandle = (categoryId: string) => {
    if (selectedAvatarVersion) {
      dispatch(GetAvatarCategoryById(selectedAvatarVersion.id, categoryId))
      setCategoryDialog({
        open: true,
        edit: true
      });
    }
  }

  const deleteCategoryHandle = (categoryId: string) => {
    if (selectedAvatarVersion) {
      dispatch(GetAvatarCategoryById(selectedAvatarVersion.id, categoryId))
      setOpenDeleteCategory(true)
    }
  }

  const addNewCategoryHandle = () => {
    setCategoryDialog({
      open: true,
      edit: false
    });
  };

  const handleCloseDialog = () => {
    if (openNewFlow) {
      setOpenNewFlow(false);
      dispatch(AddFlowReset());
    }
    if (openDeleteCategory) {
      setOpenDeleteCategory(false);
    }
    if (categoryDialog.open) {
      setCategoryDialog({
        open: false,
        edit: false
      });
      dispatch(CategoryInputReset());
    }
  };

  const addNewFlowHandle = (categoryId: string) => {
    setOpenNewFlow(true);
    setCategoryId(categoryId);
  };

  const handleSubmitCategoryDialog = () => {
    if (selectedAvatarVersion && !checkInputs(true)) {
      if (categoryDialog.edit && selectedCategory) {
        dispatch(EditAvatarCategory(selectedAvatarVersion.id, selectedCategory.id))
      }
      else
        dispatch(AddAvatarCategory(selectedAvatarVersion.id));
      handleCloseDialog();
    }
  };

  const handleSubmitDeleteCategoryDialog = () => {
    if (selectedAvatarVersion && selectedCategory) {
      dispatch(DeleteAvatarCategory(selectedAvatarVersion.id, selectedCategory.id))
      handleCloseDialog();
    }
  }

  const handleSubmitFlowDialog = () => {
    if (selectedAvatarVersion && !checkInputs(false)) {
      dispatch(AddAvatarFlow(selectedAvatarVersion.id, categoryId));
      handleCloseDialog();
    }
  };

  const checkInputs = (category: boolean): boolean => {
    let state;
    let error;

    if (category) {
      state = categoryState;
      error = addCategoryError;
    }
    else {
      state = flowState;
      error = addFlowError;
    }

    let result = false;

    if (state.en === "") {
      error("enError", true);
      result = true;
    } else error("enError", false);

    if (state.de === "") {
      error("deError", true);
      result = true;
    } else error("deError", false);

    if (state.fr === "") {
      error("frError", true);
      result = true;
    } else error("frError", false);

    if (state.it === "") {
      error("itError", true);
      result = true;
    } else error("itError", false);

    return result;
  };

  const onChangeInputs = (category: boolean, field: string, value: string) => {
    if (category)
      dispatch(AddCategoryChangeInputs({ field, value }));
    else dispatch(AddFlowChangeInputs({ field, value }));
  };

  const addCategoryError = (field: string, value: boolean) => {
    dispatch(AddCategoryInputError({ field, value }));
  };

  const addFlowError = (field: string, value: boolean) => {
    dispatch(AddFlowInputsError({ field, value }));
  };

  const handleSnackBarClose = () => {
    dispatch(SnackClose())
  };

  let versionOptions = getVersionOptions();

  const selectTheme = (
    <CustomSelect
      options={[
        {
          name: "Light",
          value: "light",
        },
        {
          name: "Dark",
          value: "dark",
        },
      ]}
      onSelectChange={onThemeChange}
      selected={dark ? "dark" : "light"}
    />
  );

  const selectLang = (
    <CustomSelect
      options={[
        {
          name: "English",
          value: "en",
        },
        {
          name: "German",
          value: "de",
        },
        {
          name: "French",
          value: "fr",
        },
        {
          name: "Italian",
          value: "it",
        },
      ]}
      onSelectChange={onLanguageChange}
      selected={language}
    />
  );

  const flowPreview = (
    <>
      {previewLoading ? (
        <Loader />
      ) : (
        <>
          {selectedAvatarVersion && selectedFlow && selectedCategory ? (
            <>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item xs={4}></Grid>
                <Grid container>
                  <Grid item xs={4} className={classes.selectTheme}>
                    {selectTheme}
                  </Grid>
                  <Grid item xs={4}>
                    {selectLang}
                  </Grid>
                </Grid>
              </Grid>
              <AvatarFlowPreview
                cells={selectedFlow.cells}
                category={selectedCategory}
                language={language}
                themeDark={dark}
              />
              <Link
                to={`/app/avatar/${selectedAvatarVersion.id}/categories/${selectedCategory.id}/${selectedFlow.id}`}
              >
                <Fab
                  color="primary"
                  className={classes.fab}
                  aria-label="edit"
                  onClick={handleEditFlow}
                >
                  <Edit />
                </Fab>
              </Link>
            </>
          ) : (
            <Typography className={classes.noFlow}>
              No flow selected.
            </Typography>
          )}
        </>
      )}
    </>
  );

  const versionSelect = (
    <>
      {selectedAvatarVersion ? (
        <>
          <Grid className={classes.versionSelect}>
            <CustomSelect
              options={versionOptions}
              onSelectChange={onVersionChange}
              selected={selectedAvatarVersion.id}
            />
          </Grid>
          <AvatarAccordion
            categoryList={selectedAvatarVersion.categoryList}
            onFlowClick={onFlowClick}
            onAddNewCategoryClick={addNewCategoryHandle}
            onAddNewFlowClick={addNewFlowHandle}
            onEditCategoryClick={editCategoryHandle}
            onDeleteCategoryClick={deleteCategoryHandle}
          />
        </>
      ) : (
        <Container
          maxWidth={false}
          disableGutters={true}
          className={classes.loader}
        >
          <Loader />
        </Container>
      )}
    </>
  );

  const addCategoryDialogContent = (
    <>
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="categoryTitleEn"
        label="EN"
        name="categoryTitleEn"
        fullWidth
        error={categoryState.enError}
        value={categoryState.en}
        onChange={(event) => onChangeInputs(true, "en", event.target.value)}
      />
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="categoryTitleDe"
        label="DE"
        name="categoryTitleDe"
        fullWidth
        error={categoryState.deError}
        value={categoryState.de}
        onChange={(event) => onChangeInputs(true, "de", event.target.value)}
      />
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="categoryTitleFr"
        label="FR"
        name="categoryTitleFr"
        fullWidth
        error={categoryState.frError}
        value={categoryState.fr}
        onChange={(event) => onChangeInputs(true, "fr", event.target.value)}
      />
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="categoryTitleIt"
        label="IT"
        name="categoryTitleIt"
        fullWidth
        error={categoryState.itError}
        value={categoryState.it}
        onChange={(event) => onChangeInputs(true, "it", event.target.value)}
      />
    </>
  );

  const addCategoryDialog = (
    <CustomDialog
      open={categoryDialog.open}
      onClose={handleCloseDialog}
      title={categoryDialog.edit ? "Edit category" : "Add new category"}
      contentText={categoryDialog.edit ? "Please type in the new title:" : "Please type in the title for the new category:"}
      content={addCategoryDialogContent}
      actions={[
        {
          text: "Cancel",
          click: handleCloseDialog,
          primary: false,
        },
        {
          text: "Submit",
          click: handleSubmitCategoryDialog,
          primary: true,
        },
      ]}
    />
  );

  const deleteCategoryDialog = (
    <CustomDialog
      open={openDeleteCategory}
      onClose={handleCloseDialog}
      title="Delete this category?"
      contentText={`This action will result in deleting the category - ${selectedCategory?.title.en}.`}
      actions={[
        {
          text: "Cancel",
          click: handleCloseDialog,
          primary: false,
        },
        {
          text: "Delete",
          click: handleSubmitDeleteCategoryDialog,
          primary: true,
        },
      ]}
    />
  );

  const addFlowDialogContent = (
    <>
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="flowTitleEn"
        label="EN"
        name="flowTitleEn"
        fullWidth
        error={flowState.enError}
        value={flowState.en}
        onChange={(event) => onChangeInputs(false, "en", event.target.value)}
      />
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="flowTitleDe"
        label="DE"
        name="flowTitleDe"
        fullWidth
        error={flowState.deError}
        value={flowState.de}
        onChange={(event) => onChangeInputs(false, "de", event.target.value)}
      />
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="flowTitleFr"
        label="FR"
        name="flowTitleFr"
        fullWidth
        error={flowState.frError}
        value={flowState.fr}
        onChange={(event) => onChangeInputs(false, "fr", event.target.value)}
      />
      <TextField
        required
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="flowTitleIt"
        label="IT"
        name="flowTitleIt"
        fullWidth
        error={flowState.itError}
        value={flowState.it}
        onChange={(event) => onChangeInputs(false, "it", event.target.value)}
      />
    </>
  );

  const addFlowDialog = (
    <CustomDialog
      open={openNewFlow}
      onClose={handleCloseDialog}
      title="ADD NEW FLOW"
      contentText="Please type in the title for the new flow:"
      content={addFlowDialogContent}
      actions={[
        {
          text: "Cancel",
          click: handleCloseDialog,
          primary: false,
        },
        {
          text: "Submit",
          click: handleSubmitFlowDialog,
          primary: true,
        },
      ]}
    />
  );

  const mainLoader = (
    <Container
      maxWidth={false}
      disableGutters={true}
      className={classes.loader}
    >
      <Loader />
    </Container>
  );

  const errorView = <ErrorMessage message={error} />;

  const view = (
    <>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
        className={classes.content}
      >
        <Grid container item xs={11} md={3} alignItems="center">
          <Grid item xs={12} md={11}>
            <Typography component={"h2"} variant={"h5"}>
              Categories
            </Typography>
            {versionSelect}
          </Grid>
          <Divider
            className={classes.verticalDivider}
            orientation="vertical"
            flexItem
          />
        </Grid>
        <Grid item xs={11} md={7}>
          <Divider
            className={classes.horizontalDivider}
            orientation="horizontal"
            variant="middle"
          />
          {flowPreview}
        </Grid>
      </Grid>
    </>
  );

  const snackBar = (
    <Snackbar 
    open={snackOpen}
    color={'success'}
    close={true}
    closeNotification={handleSnackBarClose}
    message={snackText}
    autoHideDuration={3000}
    place={'br'} />
  )

  return (
    <Card className={classes.main}>
      {loading ? mainLoader : ""}
      {error !== "" ? errorView : ""}
      {view}
      {addCategoryDialog}
      {addFlowDialog}
      {deleteCategoryDialog}
      {snackBar}
    </Card>
  );
}
