import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Button,
  Checkbox,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../redux/Store";
import {
  EditAvatarFlowChangeInputs,
  GetAvatarFlowById,
} from "../../../redux/avatarEdit/AvatarEditActions";
import { useState } from "react";
import { useEffect } from "react";
import Row from "../../../components/Row";
import { createRef } from "react";
import AvatarFlowPreview from "../../../components/avatar/AvatarFlowPreview";
import CustomSelect from "../../../components/CustomSelect";
import { AvatarFlowLanguage } from "../../../presenter/avatar/viewModel/AvatarViewModels";
import {
  ChangeAvatarThemeAction,
  ChangeAvatarLanguageAction,
} from "../../../redux/avatar/AvatarActions";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";

const useStyles = makeStyles((theme) => ({
  textField: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  switch: {
    marginLeft: theme.spacing(-0.1),
  },
  selectTheme: {
    marginRight: "1vw",
  },
}));

// const flowsArray: Array<AvatarText | AvatarImage | UserOptions> = [];
let flowsArray: number[] = [];
export default function AvatarFlowEdit() {
  const flow = useSelector((state: RootStore) => state.avatarEditFlowReducer);
  const { selectedFlow, selectedCategory, language, error, loading, dark } = useSelector(
    (state: RootStore) => state.avatarReducer
  );

  const [isRootFlowChecked, setRootFlowChecked] = useState(flow.isRootFlow);
  const [isPublishedChecked, setPublishedChecked] = useState(flow.isPublished);
  const [flows, setFlows] = useState<number[]>([]);
  const [clicked, setClicked] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = createRef();

  useEffect(() => {
    const routeParts = location.pathname.split("/");
    const version = routeParts[3];
    const categoryId = routeParts[5];
    const flowId = routeParts[6];

    dispatch(GetAvatarFlowById(version, categoryId, flowId));
  }, [dispatch, location]);

  const onChangeInputs = (field: string, value: string | boolean) => {
    dispatch(EditAvatarFlowChangeInputs({ field, value }));
  };
  const addNewRow = () => {
    let value;
    if (
      flowsArray.length > 0 &&
      flowsArray[flowsArray.length - 1] !== undefined
    ) {
      value = flowsArray[flowsArray.length - 1] + 1;
    } else {
      value = flowsArray.length;
    }
    flowsArray.push(value);
    setClicked(true);
  };

  const removeRow = (index: number) => {
    const id = flowsArray.indexOf(index);
    flowsArray.splice(id, 1);

    setFlows(flowsArray);
    setClicked(true);
  };

  useEffect(() => {
    setRootFlowChecked(Boolean(flow.isRootFlow));
    setPublishedChecked(Boolean(flow.isPublished));
  }, [flow.isRootFlow, flow.isPublished]);

  useEffect(() => {
    if (clicked) {
      setClicked(false);
      if (flows.length < flowsArray.length) {
        setFlows(flowsArray);
      }
    }
  }, [clicked, flows]);

  const onThemeChange = () => {
    dispatch(ChangeAvatarThemeAction());
  };

  const onLanguageChange = (value?: string) => {
    if (value)
      dispatch(ChangeAvatarLanguageAction(value as AvatarFlowLanguage));
  };

  const selectTheme = (
    <CustomSelect
      options={[
        {
          name: "Light",
          value: "light",
        },
        {
          name: "Dark",
          value: "dark",
        },
      ]}
      onSelectChange={onThemeChange}
      selected={dark ? "dark" : "light"}
    />
  );

  const selectLang = (
    <CustomSelect
      options={[
        {
          name: "English",
          value: "en",
        },
        {
          name: "German",
          value: "de",
        },
        {
          name: "French",
          value: "fr",
        },
        {
          name: "Italian",
          value: "it",
        },
      ]}
      onSelectChange={onLanguageChange}
      selected={language}
    />
  );

  const preview = (
    <Grid item xs={6}>
      {selectedFlow && selectedCategory ? (
        <>
          <Grid container>
            <Grid item xs={4} className={classes.selectTheme}>
              {selectTheme}
            </Grid>
            <Grid item xs={4}>
              {selectLang}
            </Grid>
          </Grid>
          <AvatarFlowPreview
            cells={flow.cells}
            category={selectedCategory}
            language={language}
            themeDark={dark}
          />
        </>
      ) : null}
    </Grid>
  );

  const form = (
    <Grid item xs={6}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            checked={isPublishedChecked}
            onChange={(event) => {
              setPublishedChecked(event.target.checked);
              onChangeInputs("isPublished", event.target.checked);
            }}
            color="primary"
          />
        }
        label="Published"
        labelPlacement="start"
      />
      <TextField
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="flowId"
        label="Flow Id"
        name="flowId"
        fullWidth
        value={flow.id}
        onChange={(event) => onChangeInputs("id", event.target.value)}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="triggerId"
        label="Trigger Id"
        name="triggerId"
        fullWidth
        value={flow.triggerId}
        onChange={(event) => onChangeInputs("triggerId", event.target.value)}
      />
      <TextField
        margin="normal"
        className={classes.textField}
        variant="outlined"
        id="comment"
        label="Comment"
        name="comment"
        fullWidth
        value={flow.comment}
        onChange={(event) => onChangeInputs("comment", event.target.value)}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isRootFlowChecked}
            onChange={(event) => {
              setRootFlowChecked(event.target.checked);
              onChangeInputs("isRootFlow", event.target.checked);
            }}
            name="isRootFlow"
          />
        }
        label="Is root flow"
        labelPlacement="end"
      />
      <Grid item xs={12} innerRef={ref}>
        {flows.length > 0
          ? flows.map((flow) => (
              <Row key={flow} index={flow} removeRow={removeRow} />
            ))
          : ""}
        <Button onClick={addNewRow}>Add new row</Button>
      </Grid>
    </Grid>
  );

  const errorView = (error: string) => <ErrorMessage message={error} />;

  if (error) return errorView(error);
  else if (flow.error) return errorView(flow.error);
  else if (flow.loading || loading) return <Loader />
  else
    return (
      <Grid container>
        {preview} {form}
      </Grid>
    );
}
