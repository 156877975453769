import { Grid } from "@material-ui/core";
import Image from 'material-ui-image'

interface AvatarImageChatProps {
  url: string;
  aspect: number;
  cellClass: string;
  cellTriangleClass: string;
}


export default function AvatarImageChat(props: AvatarImageChatProps) {
  const { url, aspect, cellClass, cellTriangleClass } = props

  return (
    <Grid>
      <Grid className={cellTriangleClass}>
      </Grid>
      <Grid className={cellClass}>
        <Image
          src={url}
          aspectRatio={aspect}>
        </Image>
      </Grid>
    </Grid>
  );
}
