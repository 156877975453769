import IBasicOutput from "../../../../gui/presenter/output/IBasicOutput";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import { IDeleteAvatarCategoryGateway } from "../gateway/IDeleteAvatarCategoryGateway";

export class DeleteAvatarCategoryError extends Error {
    constructor(message: string) {
        super(`[deleteAvatarCategory] Interactor Error - ${message}`);
    }
}

export interface IDeleteAvatarCategoryInput {
    deleteAvatarCategory(version: string, categoryId: string): Promise<void>;
}


export class DeleteAvatarCategoryInteractor implements IDeleteAvatarCategoryInput {
    constructor(
        private _output: IBasicOutput<StringId>,
        private _gateway: IDeleteAvatarCategoryGateway
    ) { }

    public async deleteAvatarCategory(version: string, categoryId: string): Promise<void> {
        try {
            await this.interact(StringId.create(version), StringId.create(categoryId));
        } catch (e) {
            this._output.displayErrorResponse(new DeleteAvatarCategoryError(e.message))
        }
    }

    private async interact(version: StringId, categoryId: StringId) {
        await this._gateway.deleteAvatarCategory(version, categoryId)
        this._output.displaySuccessResponse(categoryId);
    }
}