import { Card, Grid, makeStyles } from "@material-ui/core";
import {
  AvatarCategoryViewViewModel,
  AvatarImageViewModel,
  AvatarTextViewModel,
  UserOptionsViewModel,
} from "../../presenter/avatar/viewModel/AvatarViewModels";
import AvatarImageChat from "./AvatarImageChat";
import AvatarTextChat from "./AvatarTextChat";
import UserOptionsChat from "./UserOptionsChat";

interface AvatarFlowPreviewProps {
  cells: Array<
    UserOptionsViewModel | AvatarTextViewModel | AvatarImageViewModel
  >;
  category: AvatarCategoryViewViewModel;
  language: "en" | "de" | "fr" | "it";
  themeDark: boolean;
}

const useStyles = makeStyles((theme) => ({
  cardDark: {
    backgroundColor: "#293330",
  },
  card: {
    marginTop: "20px",
    padding: "10px",
    marginRight: "5vw",
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px'
    },
  },
  main: {
    background: "#F0F7F3",
    // padding: 3,
    height: '65vh',
    overflowY: 'scroll'
  },
  mainDark: {
    background: "#293330",
  },
  title: {
    marginTop: theme.spacing(3),
  },
  cell: {
    margin: 10,
    width: "50%",
    textAlign: "center",
  },
  link: {
    color: '#2CDEA8'
  },
  cellTriangle: {
    left: '-18px',
    position: 'relative',
    top: '15px',
    width: '10px',
    border: '12px solid',
    borderColor: 'transparent white transparent transparent',
  },
  cellTriangleDark: {
    borderColor: 'transparent #384642 transparent transparent'
  },
  cellDark: {
    backgroundColor: "#384642 !important" ,
    color: "#ffffff",
  },
  cellContent: {
    marginTop: '-25px',
    width: '100%',
    backgroundColor: 'white',
    padding: '1em',
    textAlign: 'left',
    lineHeight: '1.5em',
    borderRadius: '15px',
    wordWrap: 'break-word',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 7px 15px;',
    fontSize: '16px'
  }
}));

export default function AvatarFlowPreview(props: AvatarFlowPreviewProps) {
  const classes = useStyles();

  const { cells, language, themeDark } = props;

  const linkDecorator = (href:string, key: string) => (
    <a href={href} key={key} className={classes.link}>
      {href}
    </a>
 );

  const checkCellType = (
    cell: UserOptionsViewModel | AvatarTextViewModel | AvatarImageViewModel
  ) => {
    const text = cell.text[language];
    const type = cell.type;
    if (type === "userOption") return <UserOptionsChat text={text} />;
    if (type === "avatarText")
      return (
        <AvatarTextChat
          text={text}
          cellClass={`${classes.cellContent} ${themeDark ? classes.cellDark : ''}`}
          cellTriangleClass={`${classes.cellTriangle} ${themeDark ? classes.cellTriangleDark : ''}`}
          linkDecorator={linkDecorator}
        />
      );
    if (type === "avatarImage")
      return (
        <AvatarImageChat
          url={text}
          aspect={(cell as AvatarImageViewModel).aspect}
          cellClass={`${classes.cellContent} ${themeDark ? classes.cellDark : ''}`}
          cellTriangleClass={`${classes.cellTriangle} ${themeDark ? classes.cellTriangleDark : ''}`}
        />
      );
  };

  const showCells = (
    <>
      {cells.map((el, index) => {
        return (
          <Grid item xs={8} md={6} key={index} className={classes.cell}>
            {checkCellType(el)}
          </Grid>
        );
      })}
    </>
  );

  return (
    <Card raised className={`${classes.card} ${themeDark ? classes.cardDark : ''}`}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        className={`${classes.main} ${themeDark ? classes.mainDark : ''}`}
      >
        {showCells}
      </Grid>
    </Card>
  );
}
