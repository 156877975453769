import { withStyles, Theme, createStyles, InputBase, Select, MenuItem, makeStyles } from "@material-ui/core";

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid',
            borderColor: 'rgba(118, 118, 118, 0.45)',
            fontSize: 18,
            paddingTop: '18px',
            paddingLeft: '10px',
            height: '29px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            }
        },
    }),
)(InputBase);

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: 16,
        width: '100%'
    },
}))

interface CustomSelectProps {
    options: SelectOptionProps[];
    selected: string
    onSelectChange: (value?: string) => void;
}

export interface SelectOptionProps {
    name: string;
    value: string;
}

export default function CustomSelect(props: CustomSelectProps) {

    const classes = useStyles()
    const { options, selected, onSelectChange } = props

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onSelectChange(event.target.value as string);
    };

    return (
        <Select
            id="demo-customized-select"
            value={selected}
            onChange={handleChange}
            input={<BootstrapInput />}
            className={classes.main}
        >
            {options.map((option, index) => {
                return (
                    <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
                );
            })}
        </Select>
    );
}
