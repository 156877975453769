import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import AvatarFlow from "../../../../domain/modules/avatar/entity/AvatarFlow";
import { AvatarFlowData } from "../../../dataSource/avatarDataSource/IAvatarDataSource"
import IMapper from "../../../IMapper";

export default class AvatarFlowMapper implements IMapper<AvatarFlowData, AvatarFlow> {
    map(input: AvatarFlowData): AvatarFlow {
        const { flowId, isDrafted, isPublished, name } = input;
        return new AvatarFlow({
            isPublished,
            isDrafted,
            name: NotEmptyString.create(name)
        }, StringId.create(flowId))
    }
}
