import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import Entity from "../../../base/entity/Entity";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import AvatarFlowList from "../valueObject/AvatarFlowList";

export class AvatarCategoryError extends Error {
    constructor(message: string) {
        super(`[AvatarCategoryError] Error - ${message}`);
    }
}

interface AvatarCategoryProps {
    name: NotEmptyString,
    flowList: AvatarFlowList;
}

export default class AvatarCategory extends Entity<AvatarCategoryProps, string> {
    get id(): StringId {
        return StringId.create(this._id.getId());
    }

    get name(): NotEmptyString {
        return this.props.name;
    }

    get flowList(): AvatarFlowList {
        return this.props.flowList;
    }

    public equals(object: AvatarCategory): boolean {
        return super.equals(object) &&
            this.props.name.equals(object.props.name) &&
            (this.props.flowList.flows.length === object.props.flowList.flows.length) &&
            this.props.flowList.flows.every(flow => {
                return object.props.flowList.flows.some(secondFlow => {
                    return flow.equals(secondFlow)
                })
            })
    }
}