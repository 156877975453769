import { Dispatch } from 'redux';
import { dependencyContainer } from '../../..';
import { DeleteAvatarCategoryInteractor } from '../../../domain/modules/avatar/useCase/DeleteAvatarCategoryUseCase';
import { EditAvatarCategoryInteractor } from '../../../domain/modules/avatar/useCase/EditAvatarCategoryUseCase';
import { GetAvatarCategoryByIdInteractor } from '../../../domain/modules/avatar/useCase/GetAvatarCategoryByIdUseCase';
import { GetAvatarFlowPreviewInteractor } from '../../../domain/modules/avatar/useCase/GetAvatarFlowPreviewUseCase';
import { GetAvatarsInteractor } from '../../../domain/modules/avatar/useCase/GetAvatarsUseCase';
import DeleteAvatarCategoryPresenter from '../../presenter/avatar/DeleteAvatarCategoryPresenter';
import EditAvatarCategoryPresenter from '../../presenter/avatar/EditAvatarCategoryPresenter';
import GetAvatarCategoryByIdPresenter from '../../presenter/avatar/GetAvatarCategoryByIdPresenter';
import AvatarFlowPreviewPresenter from '../../presenter/avatar/GetAvatarFlowPreviewPresenter';
import GetAvatarsPresenter from '../../presenter/avatar/GetAvatarsPresenter';
import AvatarCategoryPresentation from '../../presenter/avatar/presentation/AvatarCategoryPresentation';
import AvatarFlowPresentation from '../../presenter/avatar/presentation/AvatarFlowPresentation';
import AvatarPresentation from '../../presenter/avatar/presentation/AvatarPresentation';
import { AvatarFlowLanguage } from '../../presenter/avatar/viewModel/AvatarViewModels';
import ReduxDispatch from '../../presenter/ReduxDispatch';
import { RootStore } from '../Store';
import { CHANGE_AVATAR_THEME, SELECT_AVATAR_VERSION, AVATAR_LOADING, NEW_AVATAR_CATEGORY, CHANGE_AVATAR_LANGUAGE, RESET_AVATAR_FLOW_PREVIEW, GET_AVATAR_FLOW_PREVIEW_LOADING } from './AvatarActionTypes';

export const GetAvatarsAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: AVATAR_LOADING
    })

    const gateway = dependencyContainer.dependency.gatewayFactory.getGetAvatarsGateway()

    new GetAvatarsInteractor(new GetAvatarsPresenter(new ReduxDispatch(dispatch), new AvatarPresentation()), gateway)
        .getAvatars();
}

export const SetAvatarFlowPreview = (version: string, categoryId: string, flowId: string) => (dispatch: Dispatch) => {
    dispatch({
        type: GET_AVATAR_FLOW_PREVIEW_LOADING
    })
    new GetAvatarFlowPreviewInteractor(
        new AvatarFlowPreviewPresenter(
            new ReduxDispatch(dispatch),
            new AvatarFlowPresentation(),
            new AvatarCategoryPresentation()
        ),
        dependencyContainer.dependency.gatewayFactory.getGetAvatarFlowPreviewGateway())
        .getAvatarFlowPreview(version, categoryId, flowId)
}

export const ResetAvatarFlowPreview = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_AVATAR_FLOW_PREVIEW
    })
}

export const NewAvatarCategoryAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: NEW_AVATAR_CATEGORY,
    })
}

export const ChangeAvatarThemeAction = () => async (dispatch: Dispatch) => {
    dispatch({
        type: CHANGE_AVATAR_THEME,
    })
}

export const ChangeAvatarLanguageAction = (value: AvatarFlowLanguage) => async (dispatch: Dispatch) => {
    dispatch({
        type: CHANGE_AVATAR_LANGUAGE,
        payload: {
            language: value
        }
    })
}

export const ChangeAvatarVersionAction = (avatarVersion: string) => async (dispatch: Dispatch, getState: () => RootStore) => {
    const { avatarList } = getState().avatarReducer
    const avatar = avatarList.filter((a) => {
        return a.id === avatarVersion
    })
    dispatch({
        type: SELECT_AVATAR_VERSION,
        payload: {
            avatar: avatar[0]
        }
    })
}

export const GetAvatarCategoryById = (version: string, categoryId: string) => (dispatch: Dispatch) => {
    dispatch({ type: AVATAR_LOADING })

    new GetAvatarCategoryByIdInteractor(new GetAvatarCategoryByIdPresenter(new ReduxDispatch(dispatch), new AvatarCategoryPresentation()),
        dependencyContainer.dependency.gatewayFactory.getAvatarCategoryByIdGateway())
        .getAvatarCategoryById(version, categoryId)
}

export const EditAvatarCategory = (version: string, categoryId: string) => (dispatch: Dispatch, getState: () => RootStore) => {
    dispatch({ type: AVATAR_LOADING })

    const { en, de, fr, it } = getState().avatarCategoryReducer;

    new EditAvatarCategoryInteractor(new EditAvatarCategoryPresenter(new ReduxDispatch(dispatch), new AvatarCategoryPresentation()),
        dependencyContainer.dependency.gatewayFactory.getEditAvatarCategoryGateway())
        .editAvatarCategory(version, categoryId, { en, de, fr, it })
}

export const DeleteAvatarCategory = (version: string, categoryId: string) => (dispatch: Dispatch) => {
    dispatch({ type: AVATAR_LOADING })

    new DeleteAvatarCategoryInteractor(new DeleteAvatarCategoryPresenter(new ReduxDispatch(dispatch)),
        dependencyContainer.dependency.gatewayFactory.getDeleteAvatarCategoryGateway())
        .deleteAvatarCategory(version, categoryId)
}