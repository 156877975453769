
import { AvatarFlowInputs } from "../../../../domain/modules/avatar/useCase/processor/AddAvatarFlowInputProcessor";
import { AvatarFlowInputData } from "../../../dataSource/avatarDataSource/IAvatarDataSource";
import IMapper from "../../../IMapper";

export default class AvatarFlowInputDataMapper implements IMapper<AvatarFlowInputs, AvatarFlowInputData> {
    map(input: AvatarFlowInputs): AvatarFlowInputData {
       const { title } = input;
       return {
           en: title.en.value,
           de: title.de.value, 
           fr: title.fr.value,
           it: title.it.value
       }
    }
}