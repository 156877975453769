import { makeStyles, Container, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import React from "react";
import AppRouter from "./components/router/AppRouter";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2CDEA8'
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#A4A4A4'
    },
    success: {
      main: '#2CDEA8'
    },
    error: {
      main: '#DE4A2C'
    },
    text: {
      primary: '#000000'
    }
  },
  props: {
    MuiButton: {
      variant: 'contained'
    },
    MuiTextField: {
      fullWidth: true,
      color: 'primary'
    },
    MuiCheckbox: {
      color: 'primary'
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        color: '#FFFFFF',
        backgroundColor: '#A4A4A4',
        '&:hover': {
          backgroundColor: '#A4A4A4',
        }
      }
    },
    MuiSelect:{
      root: {
        paddingLeft: '5px'
      }
    },
    MuiTextField: {
      root: {
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#2CDEA8'
        },
        '&:hover .MuiInputLabel-outlined': {
          color: '#2CDEA8'
        }
      }
    },
    MuiCircularProgress: {
      root: {
        '& .MuiCircularProgress-svg': {
          color: '#2CDEA8'
        }
      }
    },
    MuiInputAdornment: {
      root: {
        '& .MuiInputBase-adornedStart': {
          color: '#2CDEA8'
        }
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    overflowX: 'hidden',
    height: '100vh',
    width: '100vw',
    background: '#F0F7F3'
  },
}))

function App() {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters={true} className={classes.main}>
        <AppRouter />
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
