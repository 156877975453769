import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import AvatarImage from "../../../../domain/modules/avatar/cell/AvatarImage";
import AvatarText from "../../../../domain/modules/avatar/cell/AvatarText";
import UserOptions from "../../../../domain/modules/avatar/cell/UserOptions";
import AvatarFlowView from "../../../../domain/modules/avatar/entity/view/AvatarFlowView";
import MultilingualString from "../../../../domain/modules/avatar/valueObject/MultilingualString";
import { AvatarFlowViewData, AvatarImageData, AvatarTextData, UserOptionsData } from "../../../dataSource/avatarDataSource/IAvatarDataSource";
import IMapper from "../../../IMapper";

export default class AvatarFlowViewMapper implements IMapper<AvatarFlowViewData, AvatarFlowView> {
    map(input: AvatarFlowViewData): AvatarFlowView {
        const { title, isRootFlow, chatFlow, comment, triggerId } = input
        return new AvatarFlowView({
            title: MultilingualString.create(
                NotEmptyString.create(title.en),
                NotEmptyString.create(title.de),
                NotEmptyString.create(title.fr),
                NotEmptyString.create(title.it)
            ),
            isRootFlow: isRootFlow,
            comment,
            triggerId: triggerId ? StringId.create(triggerId) : undefined,
            cells: this.mapCells(chatFlow)
        }, StringId.create(input.flowId))
    }

    mapCells(input: Array<UserOptionsData | AvatarTextData | AvatarImageData>): Array<UserOptions | AvatarText | AvatarImage> {
        const avatarCells = input.map(cell => {
            switch (cell.type) {
                case 'userOption':
                    return this.mapUserOptions(cell as UserOptionsData)
                case 'avatarImage':
                    return this.mapAvatarImage(cell as AvatarImageData)
                default:
                    return this.mapAvatarText(cell as AvatarTextData)
            }
        })
        return avatarCells as Array<UserOptions | AvatarText | AvatarImage>
    }

    mapUserOptions(cell: UserOptionsData): UserOptions {
        return new UserOptions({
            text: MultilingualString.create(
                NotEmptyString.create(cell.text.en),
                NotEmptyString.create(cell.text.de),
                NotEmptyString.create(cell.text.fr),
                NotEmptyString.create(cell.text.it)
            ),
            goTo: cell.goTo,
            typingAnimationLength: cell.typingAnimationLength
        }, StringId.create(cell.id))
    }

    mapAvatarText(cell: AvatarTextData): AvatarText {
        return new AvatarText({
            text: MultilingualString.create(
                NotEmptyString.create(cell.text.en),
                NotEmptyString.create(cell.text.de),
                NotEmptyString.create(cell.text.fr),
                NotEmptyString.create(cell.text.it)
            ),
            typingAnimationLength: cell.typingAnimationLength
        }, StringId.create(cell.id))
    }

    mapAvatarImage(cell: AvatarImageData): AvatarImage {
        return new AvatarImage({
            text: MultilingualString.create(
                NotEmptyString.create(cell.url.text.en),
                NotEmptyString.create(cell.url.text.de),
                NotEmptyString.create(cell.url.text.fr),
                NotEmptyString.create(cell.url.text.it)
            ),
            aspect: cell.url.aspect,
            typingAnimationLength: cell.typingAnimationLength
        }, StringId.create(cell.id))
    }
}