import { MouseEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../components/ErrorMessage";
import { GetUtilities } from "../../../redux/utilityList/UtilityListActions";
import { RootStore } from "../../../redux/Store";
import { useHistory } from "react-router";
import { UtilityViewModel } from "../../../presenter/utility/viewmodel/UtilityViewModel";
import Loader from "../../../components/Loader";
import { SnackClose } from "../../../redux/base/BaseActions";
import UtilityTable from "../../../components/table/UtilityTable";
import Snackbar from "../../../components/Snackbar";
import Container from "@material-ui/core/Container";
import React from "react";
import { useLocation } from "react-router-dom";

export default function Utilities() {
  const { loading, error, utilityList } = useSelector(
    (state: RootStore) => state.utilityListReducer
  );

  const { snackOpen, snackText } = useSelector(
    (state: RootStore) => state.baseReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ref = React.createRef();

  const handleEdit = (_event: MouseEvent<HTMLButtonElement>, utility: UtilityViewModel | UtilityViewModel[]) => {
    dispatch(SnackClose());
    const id = (utility as UtilityViewModel).id;
    history.push({ pathname: `${location.pathname}/${id}` });
  }

  const handleSnackBarClose = () => {
    dispatch(SnackClose());
  }

  useEffect(() => {
    dispatch(GetUtilities());
  }, [dispatch]);

  const errorView = <ErrorMessage message={error} />;
  const loadingView = <Loader />;
  const viewToRender = (
    <Container maxWidth={false} disableGutters={true} innerRef={ref}>
      <UtilityTable
        utilityList={utilityList}
        handleEdit={handleEdit}
      />
      <Snackbar
        open={snackOpen}
        color={'success'}
        close={true}
        closeNotification={handleSnackBarClose}
        message={snackText}
        autoHideDuration={3000}
        place={'br'}
      />
    </Container>
  );

  if (!error && !loading) {
    return viewToRender;
  } else if (loading) {
    return loadingView;
  } else {
    return errorView;
  }
}
