import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Grid, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { AvatarCategoryViewModel, AvatarFlowViewModel } from '../presenter/avatar/viewModel/AvatarViewModels';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const Accordion = withStyles({
  root: {
    marginLeft: -10,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:not(:first-child)': {
      marginTop: -10
    },
    '&$expanded:first-child': {
      marginTop: 10
    },
    '&:first-child': {
      marginTop: 5
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginLeft: -10,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    maxHeight: 40,
    '&$expanded': {
      minHeight: 40,
    }
  },
  content: {
    justifyContent: 'space-between',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    marginTop: -15,
    marginBottom: -25,
    paddingLeft: theme.spacing(5),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  categorySummary: {
    width: '100%'
  },
  categoryGrid: {

  },
  categoryMoreIcon: {
    float: 'right',
    marginTop: -3,
    padding: '3px 3px 3px',
    "&:hover": {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    transition: 'none !important'
  },
  categoryTitle: {
    width: '100%',
    fontSize: '18px',
    wordBreak: 'break-word',
  },
  flowTitle: {
    fontSize: '15px'
  },
  icon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    cursor: 'default',
    fontSize: 'medium',
    marginRight: 5,
    marginTop: 5.6
  },
  addNew: {
    color: theme.palette.primary.dark,
    fontStyle: 'italic',
  } //00AC78
}))

const categoryOptionsClassList = ['MuiButtonBase-root', 'MuiList-root', 'MuiSvgIcon-root', 'MuiPopover-root']

interface AvatarAccordionProps {
  categoryList: AvatarCategoryViewModel[];
  onFlowClick: (categoryId: string, flowId: string) => void;
  onAddNewCategoryClick: () => void;
  onAddNewFlowClick: (categoryId: string) => void;
  onEditCategoryClick: (categoryId: string) => void;
  onDeleteCategoryClick: (categoryId: string) => void;
}

interface AnchorElProps {
  element: null | HTMLElement;
  categoryId: string;
}

export default function AvatarAccordion(props: AvatarAccordionProps) {
  const [anchorEl, setAnchorEl] = React.useState<AnchorElProps>({
    element: null,
    categoryId: ""
  });

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>, categoryId: string) => {
    setAnchorEl({
      element: event.currentTarget,
      categoryId
    });
  };

  const handleClose = () => {
    setAnchorEl({
      element: null,
      categoryId: ""
    });
  };

  const handleOnEditCategoryClick = () => {
    onEditCategoryClick(anchorEl.categoryId)
    handleClose()
  }

  const handleOnDeleteCategoryClick = () => {
    onDeleteCategoryClick(anchorEl.categoryId)
    handleClose()
  }

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const classes = useStyles()
  const { categoryList, onFlowClick, onAddNewCategoryClick, onAddNewFlowClick, onEditCategoryClick, onDeleteCategoryClick } = props

  useEffect(() => {
    setExpanded(false)
  }, [categoryList]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    const target = event.target as HTMLElement
    if (target.classList[0] && !categoryOptionsClassList.includes(target.classList[0])) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const showAccordions = (
    <>
      {categoryList.map((el: AvatarCategoryViewModel, index: number) => {
        return (
          <Accordion key={index} square expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <Grid className={classes.categoryGrid}>
              <AccordionSummary
                className={classes.categorySummary}
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
              >
                {expanded === `panel${index}`
                  ? <IndeterminateCheckBoxIcon className={classes.icon} />
                  : <AddBoxIcon className={classes.icon} />
                }
                <Typography className={classes.categoryTitle}>
                  {el.name}
                </Typography>
                {expanded === `panel${index}`
                  ? <IconButton
                    id="more-button"
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className={classes.categoryMoreIcon}
                    onClick={(event) => handleOptionsClick(event, el.id)}
                  >
                    <MoreHorizIcon id="more-icon" />
                  </IconButton>
                  : <></>
                }
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl.element}
                  keepMounted
                  open={Boolean(anchorEl.element)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleOnEditCategoryClick}>Edit Category</MenuItem>
                  <MenuItem onClick={handleOnDeleteCategoryClick}>Delete Category</MenuItem>
                </Menu>
              </AccordionSummary>
            </Grid>
            <AccordionDetails>
              <List component="div" disablePadding>
                {el.flowList.map((flow: AvatarFlowViewModel, index: number) => {
                  return (
                    <ListItem button dense key={index} onClick={() => onFlowClick(el.id, flow.id)}>
                      <ListItemText classes={{ primary: classes.flowTitle }} primary={flow.name} />
                    </ListItem>
                  );
                })}
                <ListItem button dense key={'newFlow'} onClick={() => onAddNewFlowClick(el.id)} >
                  <ListItemText classes={{ primary: `${classes.addNew} ${classes.flowTitle}` }} primary={'Add New Flow'} />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Accordion key={'newCategory'} square onChange={onAddNewCategoryClick} expanded={false} >
        <Grid className={classes.categoryGrid}>
          <AccordionSummary className={classes.categorySummary}>
            <Typography className={`${classes.addNew} ${classes.categoryTitle}`}>Add New Category</Typography>
          </AccordionSummary>
        </Grid>
      </Accordion>
    </>
  );

  return (
    <Grid>
      {showAccordions}
    </Grid>
  );
}
